// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

$meter-size-default: $size-small;

$meter-slice-width: round($inuit-base-spacing-unit / 6);
$meter-slice-active-width: round($inuit-base-spacing-unit / 2);
$meter-threshold-slice-width: $meter-slice-width;
$meter-indicator-width: round($inuit-base-spacing-unit / 6);

$meter-label-width: double($inuit-base-spacing-unit);
$meter-active-offset: round($inuit-base-spacing-unit * 1.5);

.#{$grommet-namespace}meter {
  display: inline-block;
  position: relative;
}

.#{$grommet-namespace}meter__slice {
  stroke-width: $meter-slice-width;
  fill: none;
  stroke: $meter-background-color;
}

.#{$grommet-namespace}meter__hot {
  cursor: pointer;
  stroke-width: double($meter-slice-active-width);
  stroke: rgba(0, 0, 0, 0.001);
}

.#{$grommet-namespace}meter__threshold {
  stroke: $threshold-color;
}

.#{$grommet-namespace}meter__value-container {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.#{$grommet-namespace}meter__graphic-container {
  white-space: normal;

  > a {
    text-decoration: none;
  }
}

.#{$grommet-namespace}meter__graphic {
  max-width: 100%;

  &:focus {
    outline: $focus-border-color solid 1px;
  }

  text {
    fill: $secondary-text-color;
  }
}

.#{$grommet-namespace}meter:not(.#{$grommet-namespace}meter--vertical) {
  .#{$grommet-namespace}meter__graphic-container {
    display: inline-block;
  }
}

.#{$grommet-namespace}meter--vertical {
  .#{$grommet-namespace}meter__graphic-container {
    display: inline-block;
    white-space: nowrap;
  }
}

.#{$grommet-namespace}meter--active {
  .#{$grommet-namespace}meter__values .#{$grommet-namespace}meter__slice {
    stroke-width: $meter-slice-active-width;
  }
}

.#{$grommet-namespace}meter__values .#{$grommet-namespace}meter__slice--active {
  stroke-width: $meter-slice-active-width;
}

@import 'objects.meter-bar';
@import 'objects.meter-curved';
@import 'objects.meter-circle';
@import 'objects.meter-arc';
@import 'objects.meter-spiral';
