.addButton {
  display: block;
  margin: 0 0 20px auto !important;
  max-width: 200px;
}

.grid {
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr);
  grid-gap: 20px;
  text-align: left;
  align-items: center;
}

.label {
  display: none;
}

.item {
  margin: 20px 0;
}

@media screen and (max-width: 800px) {
  .label {
    display: inline-block;
    font-weight: bold;
    margin-right: 5px;
  }
  .grid {
    grid-template-columns: 1fr 30px;
    grid-gap: 0;
    background: #dddddd;
    padding: 10px 15px;
    border-radius: 10px;
  }
  .header {
    display: none;
  }
  .name {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
  }
  .action {
    justify-content: center;
    margin: 5px auto;
    grid-row-start: 1;
    grid-row-end: 6;
    grid-column-start: -1;
    grid-column-end: 2;
  }
  .dataContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
  .addButton {
    max-width: 100% !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 620px) {
  .dataContainer {
    display: block;
  }
}
