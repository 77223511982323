// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.grommet.#{$grommet-namespace}app {
  @include media-query(lap-and-up) {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    overflow: visible;
  }

  // necessary to avoid scrolling the body when Layer is open
  &--hidden {
    position: fixed;
  }

  &--inline {
    position: relative;
  }

  &--centered {
    width: 100%;
    height: 100%;
  }

  .#{$grommet-namespace}app__announcer {
    left: -100%;
    right: 100%;
    z-index: -1;
    position: fixed;
  }
}
