.header {
  height: 48px;
  border-bottom: 2px solid #d7d7d7;
  font-size: 19px;
  color: #333333;
  line-height: 24px;
  font-weight: 700;
  padding: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
