// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

@mixin icon-color($icon-color) {
  fill: $icon-color;
  stroke: $icon-color;
}

@mixin icon-hover-grow() {
  transform: scale(1.1);
}

@mixin background-context-icon($light-color, $active-light-color) {
  #{$dark-background-context} {
    .#{$grommet-namespace}control-icon {
      @include icon-color($colored-icon-color);
    }

    &:active,
    &:hover {
      .#{$grommet-namespace}control-icon {
        @include icon-color($active-colored-icon-color);
      }
    }
  }

  #{$light-background-context} {
    .#{$grommet-namespace}control-icon {
      @include icon-color($light-color);
    }

    &:active,
    &:hover {
      .#{$grommet-namespace}control-icon {
        @include icon-color($active-light-color);
      }
    }
  }
}
