.grid {
  display: grid;
  grid-column-gap: 10px;
  margin: 0;
  text-align: center;
}

.singleClass {
  &:hover {
    cursor: pointer;
    background: #dddddd;
  }
}

.selectedClass {
  background: #bbff78;
}

.tableHeader {
  background: #dddddd;
  font-weight: bold;
  padding: 2px 0;
}
.tableHeaderClass {
  font-weight: normal;
}
.fullClass {
  background: darkgrey !important;
  &:hover {
    cursor: not-allowed;
  }
}
