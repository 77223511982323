@import '../../../brandConfig/colors/colors.scss';

.videoButtons {
  text-align: center;
  padding-top: 20px;

  .btnStop,
  .btnPause,
  .btnDownload {
    margin: 0 0 0 10px;
  }
}

video {
  min-width: 100% !important;
  min-height: 100% !important;
}

.cameraContainer {
  text-align: center;
  position: relative;
}

.cameraText {
  text-align: left;
}

.videoContainer {
  position: relative;
}

.liveIconContainer {
  background-color: red;
  display: inline-flex;
  position: absolute !important;
  top: 16px;
  left: 16px;
}

.liveIcon {
  background-color: red;
  color: #000000;
  font-weight: bold;
  padding: 3px 6px 3px 6px;
}

.viewerCount {
  position: relative !important;
  font-weight: bold;
  display: inline-flex;
  margin-top: 3px;
  margin-left: 10px;
  padding-right: 5px;
}

.signalServerErrorMessage {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: red;
  font-weight: 600;
}

@media (min-width: 768px) {
  .cameraContainer {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}
