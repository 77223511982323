.nextBtn {
  display: block;
  margin: 0 0 0 auto;
  width: 30% !important;
}

.title {
  font-weight: bold;
  align-items: end;
  font-size: 1.2em;
}

.currentClass {
  background: #f5f5f5;
  font-weight: bold;
  margin-bottom: 30px;
  padding: 5px 0px;
}

.noData {
  text-align: center;
  font-size: 20px;
}
