// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}image {
  max-width: 100%;
}

.#{$grommet-namespace}image--small {
  width: $size-small;
}

.#{$grommet-namespace}image--medium {
  width: $size-medium;
}

.#{$grommet-namespace}image--large {
  width: $size-large;
}

.#{$grommet-namespace}image--thumb {
  width: double($inuit-base-spacing-unit);
  height: double($inuit-base-spacing-unit);
  flex: 0 0 auto;
  object-fit: cover;

  &.#{$grommet-namespace}image--mask {
    border-radius: $inuit-base-spacing-unit;
  }
}

.#{$grommet-namespace}image--cover {
  object-fit: cover;
}

.#{$grommet-namespace}image--contain {
  object-fit: contain;
}

.#{$grommet-namespace}image--full {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.#{$grommet-namespace}image--align-top.#{$grommet-namespace}image--align-left {
  object-position: 0 0;
}

.#{$grommet-namespace}image--align-top.#{$grommet-namespace}image--align-right {
  object-position: 100% 0;
}

.#{$grommet-namespace}image--align-top:not(.#{$grommet-namespace}image--align-left):not(.#{$grommet-namespace}image--align-right) {
  object-position: 50% 0%;
}

.#{$grommet-namespace}image--align-bottom.#{$grommet-namespace}image--align-left {
  object-position: 0 100%;
}

.#{$grommet-namespace}image--align-bottom.#{$grommet-namespace}image--align-right {
  object-position: 100% 100%;
}

.#{$grommet-namespace}image--align-bottom:not(.#{$grommet-namespace}image--align-left):not(.#{$grommet-namespace}image--align-right) {
  object-position: 50% 100%;
}

.#{$grommet-namespace}image--align-left:not(.#{$grommet-namespace}image--align-top):not(.#{$grommet-namespace}image--align-bottom) {
  object-position: 0 50%;
}

.#{$grommet-namespace}image--align-right:not(.#{$grommet-namespace}image--align-top):not(.#{$grommet-namespace}image--align-bottom) {
  object-position: 100% 50%;
}

.#{$grommet-namespace}image--full-horizontal {
  width: 100%;
}

.#{$grommet-namespace}image--full-vertical {
  height: 100%;
}

.#{$grommet-namespace}image__container {
  display: flex;
  flex-direction: column;
}

.#{$grommet-namespace}image__caption {
  text-align: center;
  padding: halve($inuit-base-spacing-unit);
}

.#{$grommet-namespace}image__caption--small {
  max-width: $size-small;
}

.#{$grommet-namespace}image__caption--medium {
  max-width: $size-medium;
}

.#{$grommet-namespace}image__caption--large {
  max-width: $size-large;
}

// IE11 doesn't support object-fit :(
@mixin ie-image-hack() {
  .#{$grommet-namespace}image--full {
    width: auto;
    height: auto;
    max-width: none;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .#{$grommet-namespace}image--align-top.#{$grommet-namespace}image--align-left {
    top: 0%;
    left: 0%;
    transform: none;
  }

  .#{$grommet-namespace}image--align-top.#{$grommet-namespace}image--align-right {
    top: 0%;
    left: 100%;
    transform: translate(-100%, 0%);
  }

  .#{$grommet-namespace}image--align-top:not(.#{$grommet-namespace}image--align-left):not(.#{$grommet-namespace}image--align-right) {
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
  }

  .#{$grommet-namespace}image--align-bottom.#{$grommet-namespace}image--align-left {
    top: 100%;
    left: 0%;
    transform: translate(0%, -100%);
  }

  .#{$grommet-namespace}image--align-bottom.#{$grommet-namespace}image--align-right {
    top: 100%;
    left: 100%;
    transform: translate(-100%, -100%);
  }

  .#{$grommet-namespace}image--align-bottom:not(.#{$grommet-namespace}image--align-left):not(.#{$grommet-namespace}image--align-right) {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  .#{$grommet-namespace}image--align-left:not(.#{$grommet-namespace}image--align-top):not(.#{$grommet-namespace}image--align-bottom) {
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
  }

  .#{$grommet-namespace}image--align-right:not(.#{$grommet-namespace}image--align-top):not(.#{$grommet-namespace}image--align-bottom) {
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  @include ie-image-hack();
}

@supports (-ms-ime-align: auto) {
  @include ie-image-hack();
}
