.header {
  text-align: center;
  min-width: 400px;
}

.content {
  text-align: center;

  a {
    text-decoration: none;
    color: black;
    margin-top: 10px;
    font-size: 20px !important;
    vertical-align: middle;
  }

  a::after {
    content: '\a';
    display: block;
    margin: 15px 0px !important;
    text-decoration: none !important;
  }
}

.btn {
  display: block;
  justify-content: center !important;
  margin: 20px auto !important;
  font-weight: bold;
  padding: 6px 22px !important;
}
