// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}tab {
  padding: 0 halve($inuit-base-spacing-unit);

  @include media-query(palm) {
    padding: quarter($inuit-base-spacing-unit);
  }

  .#{$grommet-namespace}tabs--justify-start &:first-of-type,
  .#{$grommet-namespace}tabs--justify-end &:first-of-type {
    padding-left: 0;
  }

  .#{$grommet-namespace}tabs--justify-start &:last-of-type,
  .#{$grommet-namespace}tabs--justify-end &:last-of-type {
    padding-right: 0;
  }

  .#{$grommet-namespace}tabs--responsive &:first-of-type,
  .#{$grommet-namespace}tabs--responsive &:last-of-type {
    @include media-query(palm) {
      padding-left: halve($inuit-base-spacing-unit);
      padding-right: halve($inuit-base-spacing-unit);
    }
  }
}

.#{$grommet-namespace}tab__label {
  display: inline-block;
  cursor: pointer;
  padding-bottom: quarter($inuit-base-spacing-unit) + $active-border-width;
  color: $secondary-text-color;
  border-bottom: $active-border-width solid transparent;

  @include media-query(palm) {
    padding-bottom: quarter($inuit-base-spacing-unit);
  }
}

.#{$grommet-namespace}tab--active {
  .#{$grommet-namespace}tab__label {
    color: $hover-text-color;
    border-color: $hover-text-color;
  }
}

.#{$grommet-namespace}tab:hover:not(.#{$grommet-namespace}tab--active) {
  .#{$grommet-namespace}tab__label {
    border-color: $border-color;
  }
}
