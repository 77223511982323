.label {
  margin-top: 15px;
  font-weight: bold;
  padding: 5px 0px;
}

.client {
  font-weight: bold;
  padding: 5px 0px;
}

.btn {
  margin-top: 30px;
}

.question{
  margin-top: 30px;
  font-size: 1.2em;
  text-align: center;
  min-width: 600px;
}
