.root {
  background: #f5f5f5;
  border-radius: 12px;
  margin: 7px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    filter: brightness(95%);
    font-weight: bold;
  }
}

.name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.actionButtons {
  display: flex;
}

@media screen and (min-width: 300px) {
  .root svg {
    padding: 0 10px;
    cursor: pointer;
    width: auto !important;
    height: auto !important;
  }
}
