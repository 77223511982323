.classesContainer {
  display: flex;
  justify-content: center;
}

.header {
  position: relative;
  border-bottom: 2px solid #d7d7d7;
  padding: 11px 0;
  text-align: center;

  h4 {
    margin: 5px;
    text-align: center;
  }
}

.arrowLeft {
  cursor: pointer;
  position: absolute;
  left: 0;
  margin-top: -15px;
}

.arrowRight {
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-top: -15px;
}

@media screen and (min-width: 768px) {
  .header {
    text-align: left;
  }

  .arrowLeft {
    left: -35px;
    z-index: 1000;
    margin-top: -15px;
  }

  .arrowRight {
    right: -35px;
    z-index: 1000;
    margin-top: -15px;
  }
}

@media screen and (min-width: 1280px) {
  .arrowLeft {
    left: -50px;
    z-index: 1000;
    margin-top: -15px;
  }

  .arrowRight {
    right: -50px;
    z-index: 1000;
    margin-top: -15px;
  }
}

.actionContainer {
  display: flex;
  justify-content: space-between;

  a {
    margin: 0px !important;
  }
}

@media screen and (min-width: 280px) {
  .header {
    text-align: left;
  }

  .arrowLeft {
    left: 26px !important;
    cursor: pointer !important;
    z-index: 1000;
    margin-top: -15px;
  }

  .arrowRight {
    right: -5px;
    cursor: pointer !important;
    z-index: 1000;
    margin-top: -15px;
  }
}
