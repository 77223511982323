// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}check-box {
  margin-right: halve($inuit-base-spacing-unit);
  white-space: nowrap;

  html.rtl & {
    margin-right: $inuit-base-spacing-unit;
    margin-left: halve($inuit-base-spacing-unit);
  }
}

.#{$grommet-namespace}check-box:not(.#{$grommet-namespace}check-box--disabled) {
  cursor: pointer;
}

.#{$grommet-namespace}check-box:hover:not(.#{$grommet-namespace}check-box--disabled) {
  .#{$grommet-namespace}check-box__control {
    border-color: $active-icon-color;

    #{$dark-background-context} {
      border-color: $active-colored-icon-color;
    }

    #{$light-background-context} {
      border-color: $active-icon-color;
    }
  }

  .#{$grommet-namespace}check-box__input:checked + .#{$grommet-namespace}check-box__control {
    border-color: $active-icon-color;

    #{$dark-background-context} {
      border-color: $active-colored-icon-color;
    }

    #{$light-background-context} {
      border-color: $active-icon-color;
    }
  }

  .#{$grommet-namespace}check-box__label {
    color: $hover-text-color;

    #{$dark-background-context} {
      color: $active-colored-text-color;
    }

    #{$light-background-context} {
      color: $hover-text-color;
    }
  }
}

.#{$grommet-namespace}check-box__input {
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .#{$grommet-namespace}check-box__control {
    border-color: $brand-color;

    #{$dark-background-context} {
      border-color: $active-colored-icon-color;
    }

    #{$light-background-context} {
      border-color: $brand-color;
    }

    .#{$grommet-namespace}check-box__control-check {
      display: block;
    }

    + .#{$grommet-namespace}check-box__label {
      color: $text-color;

      #{$dark-background-context} {
        color: $active-colored-text-color;
      }

      #{$light-background-context} {
        color: $text-color;
      }
    }
  }

  &:focus + .#{$grommet-namespace}check-box__control {
    border-color: $focus-border-color;
    box-shadow: 0 0 1px 1px $focus-border-color;
  }
}

.#{$grommet-namespace}check-box__control {
  position: relative;
  top: -1px;
  display: inline-block;
  width: $control-size;
  height: $control-size;
  margin-right: halve($inuit-base-spacing-unit);
  vertical-align: middle;
  background-color: inherit;
  border: $control-border-width solid $icon-color;
  border-radius: $border-radius;

  html.rtl & {
    margin-right: 0;
    margin-left: halve($inuit-base-spacing-unit);
  }

  #{$dark-background-context} {
    border-color: $colored-icon-color;
  }

  #{$light-background-context} {
    border-color: $icon-color;
  }
}

.#{$grommet-namespace}check-box__control-check {
  position: absolute;
  top: -2px;
  left: -2px;
  display: none;
  width: $control-size;
  height: $control-size;
  stroke-width: round($control-border-width * 2);
  stroke: $brand-color;

  #{$dark-background-context} {
    stroke: $active-colored-icon-color;
  }

  #{$light-background-context} {
    stroke: $brand-color;
  }
}

.#{$grommet-namespace}check-box__label {
  // display and vertical-align are needed for rich custom labels
  display: inline-block;
  vertical-align: middle;
  color: $secondary-text-color;
  white-space: normal;
  word-break: break-word;

  #{$dark-background-context} {
    color: $colored-text-color;
  }

  #{$light-background-context} {
    color: $secondary-text-color;
  }
}

.#{$grommet-namespace}check-box--disabled {
  .#{$grommet-namespace}check-box__control {
    opacity: 0.5;
  }
}

.#{$grommet-namespace}check-box--reverse {
  .#{$grommet-namespace}check-box__control {
    margin-right: 0;
    margin-left: halve($inuit-base-spacing-unit);

    html.rtl & {
      margin-left: 0;
      margin-right: halve($inuit-base-spacing-unit);
    }
  }
}

.#{$grommet-namespace}check-box--toggle {
  &:hover:not(.#{$grommet-namespace}check-box--disabled) {
    .#{$grommet-namespace}check-box__control:after {
      content: '';
      border-color: $active-icon-color;

      #{$dark-background-context} {
        background-color: $active-colored-icon-color;
        border-color: $active-colored-icon-color;
      }

      #{$light-background-context} {
        background-color: none;
        border-color: $active-icon-color;
      }
    }

    .#{$grommet-namespace}check-box__input:checked + .#{$grommet-namespace}check-box__control:after {
      content: '';
      border-color: $active-icon-color;

      #{$dark-background-context} {
        background-color: $active-colored-icon-color;
        border-color: $active-colored-icon-color;
      }

      #{$light-background-context} {
        background-color: none;
        border-color: $active-icon-color;
      }
    }
  }

  // track
  .#{$grommet-namespace}check-box__control {
    width: double($control-size);
    height: $control-size;
    border-radius: $control-size;
    background-color: $control-background-color;
    border: none;
    transition: background-color 0.3s;

    #{$dark-background-context} {
      background-color: $colored-meter-background-color;
    }

    #{$light-background-context} {
      background-color: $control-background-color;
    }

    // knob
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -$control-border-width;
      left: 0px;
      width: $control-size + double($control-border-width);
      height: $control-size + double($control-border-width);
      background-color: #fff;
      border: $control-border-width solid $icon-color;
      border-radius: $control-size;
      transition: margin-left 0.3s;
      box-sizing: border-box;

      #{$dark-background-context} {
        background-color: #fff;
        border-color: $colored-icon-color;
      }

      #{$light-background-context} {
        border-color: $icon-color;
      }
    }
  }

  .#{$grommet-namespace}check-box__input {
    &:checked + .#{$grommet-namespace}check-box__control {
      background-color: $brand-color;

      #{$dark-background-context} {
        background-color: $colored-meter-background-color;

        .#{$grommet-namespace}check-box__control-check {
          stroke: transparent;
        }
      }

      #{$light-background-context} {
        background-color: $brand-color;
      }

      &:after {
        content: '';
        background-color: #fff;
        border-color: $brand-color;
        margin-left: $control-size;

        #{$dark-background-context} {
          background-color: #fff;
          border-color: $colored-icon-color;
        }

        #{$light-background-context} {
          border-color: $brand-color;
        }
      }

      .#{$grommet-namespace}check-box__control-check {
        display: none;
      }
    }
  }
}
