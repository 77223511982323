.root {
  margin: 0 auto;
}

.header {
  font-weight: bold;
  background: #fff;
  font-size: 1em;
  line-height: 1.5;
  text-align: center;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  height: 48px;

  svg {
    cursor: pointer;
    width: 24px;
  }
}

.headerItem {
  text-align: center;
  padding: 14px 6px 6px 6px;
  position: relative;
  border-right: 1px solid #eee;
}

.headerName {
  display: flex;
  justify-content: space-between;
  border-left: 1px solid #eee;
}

.item {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  padding: 4px 2px;
  height: 48px;

  svg {
    height: 18px;
    width: 20px;
  }
}

.trainerName {
  border-left: 1px solid #eee;
  text-align: left;
  padding-top: 12px;
  padding-right: 10px;
  white-space: nowrap;
  z-index: 9;
  position: relative;
}

.eventCell {
  width: 100%;
  padding: 0 !important;
}

.event {
  padding: 5px;
  background: #20c7c5;
  border-radius: 4px;
  cursor: pointer;

  p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.class {
  background: #f8f8f8;
}

.personalTraining {
  background: #ffeed6;
}

.induction {
  background: #e4fad5;
}

.gymClass {
  background: #d6e6ff;
}

.swimmingClass {
  background: #ffd6e6;
}

.tennisClass {
  background: #eed6ff;
}

.massageClass {
  background: #b3e5fc;
}

.xsOnly {
  display: none;
}

.allAttendeesUpdated {
  background: #80b91e;

  p {
    color: #fff !important;
  }
}

@media only screen and (max-width: 600px) {
  .xsHidden {
    display: none;
  }
  .xsOnly {
    display: block;
  }
  .eventCell {
    height: 50px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .eventCell {
    height: 80px;
  }
}
