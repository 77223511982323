.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 110px 1fr 50px;
  grid-gap: 20px;
  align-items: center;
}

.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: 300;
  padding: 11px 0;
  font-size: 1em;
  line-height: 1.5;
  background-color: #fff;
  color: #333;
  margin-bottom: 15px;
}

.icon {
  svg {
    width: 50px !important;
    height: 50px !important;
  }
}

.subheader {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    display: none;
  }
  .grid {
    grid-template-columns: 1fr 1fr 50px;
    grid-gap: 5px;
  }
  .singleClient {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0;
  }
  .icon {
    grid-column-end: -1;
    grid-row-start: 1;
    grid-row-end: 3;
    align-items: center;
  }
  .name {
    font-weight: bold;
    font-size: 18px;
  }
  .subheader {
    display: block;
    margin-right: 5px;
    font-size: 10px;
  }
}
