.root {
  button {
    font-weight: 700 !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
}

.cancelAttendanceButton {
  font-weight: 700 !important;
  align-items: center !important;
  width: 100%;
}
