@import '../../../brandConfig/colors/colors.scss';

.card {
  margin-bottom: 20px;
}

.card__label {
  min-height: 60px;
  background-color: $brand-color-accent2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.card__body {
  padding: 33px 43px;
  background-color: #eeeeee;
  text-align: center;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .card {
    margin-right: 14px;
    width: 220px;
  }

  .card__body {
    min-height: 224px;
    padding: 24px 13px;
  }
}

@media (min-width: 1040px) {
  .card {
    &:last-child {
      margin-right: 0;
    }
  }
}

@media (min-width: 1280px) {
  .card {
    margin-right: 20px;
    width: 380px;
  }

  .card__body {
    padding: 50px 32px;
  }
}
