.gymSelector {
  text-align: left;

  label {
    display: block;
    padding-bottom: 10px;
  }
}

.header {
  font-size: 1.1875rem;
  line-height: 1.26316;
  font-weight: 300;
}

.errorNotification {
  color: red;
  height: 24px;
}

.fullAccess {
  margin: 0 0 14px 0;
}
