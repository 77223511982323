// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

$form-horizontal-padding: $inuit-base-spacing-unit; //round($inuit-base-spacing-unit * 0.75);
$form-vertical-padding: quarter($inuit-base-spacing-unit) - $input-border-width;

$select-drop-caret: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAAcBJREFUSA3tUz9IAmEU9+7gNv8MLo7NrXpwYFtLhrQWbQWpoEuRs2MZNYnoUHNES0RNLS0hhxANzkFBLg5Jk4ee/X7iF+d5lyIIBX5wfO99v9/vve/ee5/Pt1iLCvz5CkipVOqu3+8n5nFTSZLuZVVVtxC8MYcEjWAwuCkxcDabXTJN04AZRtazarV6MEtCVOMU1diHtoWLa6VS6VVhIMMwPjVNqwHchhuPxWLv9Xr9mdi0K51O70B/BL6pKMp6uVx+oXaQgAYCviHwB0gb+NaQ8JFnxCatTCazYlnWFXiKLMt7lUrlRmh+EvCAt45GowGYcSRJ6rp+zb8TZLed5e12uw/A/MPyHtt5st2hHYlEDtl9mGH05Tafz/udHOETI4dcaqgVmNjHEhQKBYvdB4GTtdxuty9x5saTiZFDLjXUwh5ZY0KixWLxC1OQhNlCqRLNZvNkRAWHZ8TIIZcaJ4f+SA/shN8my2ti7HphD96BcNx2BsOEnAPj+K2S0+v12FQVE7OLibngmdeamIBC+wMaBpr6Qbr2wHkb+2QB85wYp47+VH9AYi6XC3Q6nSfaoVBI92oq8ZkXHxW/mQP8S+E3ZIDJn85FDg8AAAAASUVORK5CYII=);
$colored-select-drop-caret: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAASVJREFUSA3tlDGOwjAQRWNKCmoqLpByz0DPFVbcgQ4JiW77LREVNUicg0vsESi2JLwfDcramQQLCWmFsPRDZub/b8ceUxTv8d6Bf78DoaqqFav8eNJKTwOMv8DPEyaQp7yLgq8Ygx04gnmdfOAhrXnIaxxZkCjB3gjTqJgRoJuaVh6lK8kiOUoZgrzFQez+TN+8d3t1yOnYkjiBEVgy4TAl3GKrLY0rjbTRaE0QQrjAuHXWhPcFRi2e5RbUxak7xrSEzWgJVYL4y88anIHuyCdIh3KqibM2Tcrpj1mle3jk73dMv3VTTc26Jm0U8VuIQz/CVJdvBrQdGmqAA9uyqaOeh3sGDv9vZ8nc7RhHl5/iK4bg29DZuvmODhNzXar4P8bhvVbqCvor5P4CpWjxAAAAAElFTkSuQmCC);

@mixin form-field-content {
  display: block;
  width: 100%;
  border: none;
  border-radius: 0px;
  @include inuit-font-size($content-font-size);
  padding: quarter($inuit-base-spacing-unit) $form-horizontal-padding
    (quarter($inuit-base-spacing-unit) + $form-vertical-padding);
  margin-bottom: -$form-vertical-padding;

  &:focus {
    padding: quarter($inuit-base-spacing-unit) $form-horizontal-padding
      (quarter($inuit-base-spacing-unit) + $form-vertical-padding);
  }

  #{$dark-background-context} {
    color: $active-colored-text-color;
  }

  #{$light-background-context} {
    color: $text-color;
  }
}

.#{$grommet-namespace}form-field {
  position: relative;
  padding: $form-vertical-padding $form-horizontal-padding;
  border: $input-border-width solid $border-color;
  margin-bottom: -$input-border-width; // so borders overlap
  background-color: $form-field-background-color;
  color: $text-color;
  opacity: 1; // for --hidden

  @include media-query(lap-and-up) {
    width: 100%;
    // overflow: hidden; // for Firefox and IE
    overflow: auto;
    transition: all 0.4s, padding-top 0.3s 0.1s, padding-bottom 0.3s 0.1s;
  }

  @include media-query(palm) {
    display: block;
  }

  #{$dark-background-context} {
    background-color: transparent;
    color: $colored-text-color;
    border-color: $colored-border-color;
  }

  #{$light-background-context} {
    background-color: $form-field-background-color;
    color: $text-color;
    border-color: $border-color;
  }

  .#{$grommet-namespace}form--fill & {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.#{$grommet-namespace}form-field__label {
  display: block;
  @include inuit-font-size($content-small-font-size, $inuit-base-spacing-unit);
  color: $secondary-text-color;

  #{$dark-background-context} {
    color: $colored-text-color;
  }

  #{$light-background-context} {
    color: $secondary-text-color;
  }
}

.#{$grommet-namespace}form-field__contents {
  display: block;
  margin-left: -$form-horizontal-padding;
  margin-right: -$form-horizontal-padding;

  > .#{$grommet-namespace}box {
    input {
      border: none;
      padding: 0;
    }

    .#{$grommet-namespace}anchor {
      color: $control-brand-color;
      text-decoration: none;
    }
  }

  > input:not([type]),
  > input[type='text'],
  > input[type='range'],
  > input[type='email'],
  > input[type='tel'],
  > input[type='url'],
  > input[type='password'],
  > input[type='number'],
  > input[type='file'],
  > input[type='color'],
  > select,
  > textarea {
    .grommet & {
      @include form-field-content();
    }
  }

  > .#{$grommet-namespace}search-input input,
  > .#{$grommet-namespace}calendar input,
  > .#{$grommet-namespace}date-time input,
  > .#{$grommet-namespace}select:not(.#{$grommet-namespace}select--inline) input,
  > .#{$grommet-namespace}password-input input {
    .grommet & {
      @include form-field-content();
      // align left padding with other form inputs
      padding-left: $inuit-base-spacing-unit;
      padding-bottom: quarter($inuit-base-spacing-unit) + $form-vertical-padding;

      &:focus {
        padding-bottom: quarter($inuit-base-spacing-unit) + $form-vertical-padding;
      }
    }
  }

  > .#{$grommet-namespace}select:not(.#{$grommet-namespace}select--inline) .#{$grommet-namespace}select__value {
    border: none;
  }

  > input:not([type]),
  > input[type='text'],
  > input[type='range'],
  > input[type='email'],
  > input[type='tel'],
  > input[type='url'],
  > input[type='password'],
  > input[type='number'],
  > input[type='file'],
  > input[type='color'],
  > select,
  > .#{$grommet-namespace}search-input input,
  > .#{$grommet-namespace}calendar input,
  > .#{$grommet-namespace}date-time input,
  > .#{$grommet-namespace}text-input,
  > .#{$grommet-namespace}select:not(.#{$grommet-namespace}select--inline) input,
  > .#{$grommet-namespace}password-input input {
    height: $inuit-base-spacing-unit + halve($inuit-base-spacing-unit) + $form-vertical-padding;
    // for IE 11.
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      line-height: normal;
    }
  }

  > input[type='range'] {
    width: calc(100% - #{double($form-horizontal-padding)});
    margin-left: $form-horizontal-padding;
    margin-right: $form-horizontal-padding;
    padding-left: 0px;
    padding-right: 0px;
  }

  // Disable IE's clear field 'X' button
  > input::-ms-clear {
    display: none;
  }

  > select {
    display: block;
    background-image: $select-drop-caret;
    padding-right: double($inuit-base-spacing-unit);
    background-position: center right #{$form-horizontal-padding - quarter($inuit-base-spacing-unit)};
    // Extra padding is added by Firefox (more) and IE (less).
    // Leave it alone as Safari, Chrome, and Opera don't want this.
    // padding-left: #{$form-horizontal-padding - 2};

    &:focus {
      padding-right: double($inuit-base-spacing-unit);
    }

    // Firefox hack for left-aligning select with other inputs.
    _:-moz-tree-row(hover),
    &,
    &:focus {
      padding-left: ($inuit-base-spacing-unit - ($input-border-width + 2));
    }

    // IE11 hack for left-aligning select with other inputs
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding-left: ($inuit-base-spacing-unit - ($input-border-width + 1));

      &:focus {
        padding-left: ($inuit-base-spacing-unit - ($input-border-width + 1));

        #{$dark-background-context} {
          option {
            // fix white dropdown text in IE11
            color: $text-color;
          }
        }
      }
    }

    html.rtl & {
      background-position: center left #{$form-horizontal-padding - quarter($inuit-base-spacing-unit)};
    }

    #{$dark-background-context} {
      background-image: $colored-select-drop-caret;
    }

    #{$light-background-context} {
      background-image: $select-drop-caret;
    }
  }

  // For dotted firefox outline
  > select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  // Disable IE's select arrow
  > select::-ms-expand {
    display: none;
  }

  // Disable IE's background select
  > select::-ms-value {
    background: none;
    color: inherit;
  }

  > .#{$grommet-namespace}select {
    .#{$grommet-namespace}select__options {
      margin-left: $form-horizontal-padding;
      margin-right: $form-horizontal-padding;
    }
  }

  // Prevent textarea from adding extra bottom margin
  > textarea {
    vertical-align: top;
    height: auto;
    // Note that resize does nothing in IE/Edge
    resize: vertical;
  }

  > .#{$grommet-namespace}check-box,
  > .#{$grommet-namespace}radio-button {
    display: block;
    @include inuit-font-size($content-font-size);
    margin-top: $form-vertical-padding;
    margin-bottom: $form-vertical-padding;
    margin-left: $form-horizontal-padding;
    margin-right: $form-horizontal-padding;
  }

  > .#{$grommet-namespace}search-input,
  > .#{$grommet-namespace}calendar,
  > .#{$grommet-namespace}date-time {
    display: block;

    input {
      margin-left: 0px;
      margin-right: 0px;
    }

    .#{$grommet-namespace}search-input__control,
    .#{$grommet-namespace}calendar__control,
    .#{$grommet-namespace}date-time__control {
      top: auto;
      right: quarter($inuit-base-spacing-unit);
      transform: none;
      bottom: 0; // - $input-border-width;

      html.rtl & {
        right: auto;
        left: quarter($inuit-base-spacing-unit);
      }

      > .#{$grommet-namespace}button__icon {
        padding: halve($inuit-base-spacing-unit) - $button-border-width - $input-border-width;
      }
    }
  }

  > .#{$grommet-namespace}number-input {
    display: flex;
    padding-right: quarter($inuit-base-spacing-unit);

    html.rtl & {
      padding-right: 0;
      padding-left: quarter($inuit-base-spacing-unit);
    }

    input[type='number'] {
      display: inline-block;
      flex: 1;
      flex-basis: inherit; // required for firefox to prevent horizontal scrollbar
      width: 0; // required to keep reset input width and let flex-grow handle it
      border: none;
      padding: 0 $form-horizontal-padding;

      &:focus {
        padding: 0 $form-horizontal-padding;
      }

      // For some reason, .number-input input causes form fields to be
      // too wide in compact forms.
      .#{$grommet-namespace}form--compact & {
        min-width: round($inuit-base-spacing-unit * 3);
      }
    }
  }

  > input[type='file'] {
    display: inline-block;

    // Turned off, overruns file name text on compact forms.
    // &::-webkit-file-upload-button {
    //   float: right;
    // }
  }

  > .#{$grommet-namespace}table--selectable {
    @include inuit-font-size($content-font-size);

    table {
      margin-bottom: 0px;

      td:first-child,
      th:first-child {
        padding-left: $form-horizontal-padding;
      }
    }
  }

  > .#{$grommet-namespace}form-field {
    width: auto;
    margin-top: halve($inuit-base-spacing-unit);
    border: none;

    > .#{$grommet-namespace}form-field__label {
      border-top: 1px solid $border-color;
      padding-top: $form-vertical-padding;
    }
  }
}

// hiding input controls
.grommetux-button.grommetux-button--plain.grommetux-number-input__subtract,
.grommetux-button.grommetux-button--plain.grommetux-number-input__add {
  visibility: hidden !important;
}

.#{$grommet-namespace}form-field__contents--hidden {
  margin-top: 0px;
}

.#{$grommet-namespace}form-field__help {
  display: block;
  @include inuit-font-size($content-small-font-size);
  color: $secondary-text-color;

  #{$dark-background-context} {
    color: $colored-text-color;
  }

  #{$light-background-context} {
    color: $secondary-text-color;
  }
}

.#{$grommet-namespace}form-field__error {
  display: block;
  float: right;
  color: map-get($brand-status-colors, critical);
  line-height: $inuit-base-spacing-unit;

  html.rtl & {
    float: left;
  }

  #{$dark-background-context} {
    color: $brand-error-colored-color;
  }

  #{$light-background-context} {
    color: map-get($brand-status-colors, critical);
  }
}

.#{$grommet-namespace}form-field--text {
  cursor: pointer;

  .#{$grommet-namespace}form-field__label {
    cursor: pointer;
  }
}

.#{$grommet-namespace}form-field--hidden {
  @include media-query(palm) {
    display: none;
  }

  @include media-query(lap-and-up) {
    border: none;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    opacity: 0;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.2s, all 0.4s;
  }
}

.#{$grommet-namespace}form-field--error {
  z-index: 1;
  border-color: map-get($brand-status-colors, critical);

  #{$dark-background-context} {
    border-color: $brand-error-colored-color;
  }

  #{$light-background-context} {
    border-color: map-get($brand-status-colors, critical);
  }
}

.#{$grommet-namespace}form-field--focus {
  z-index: 2;
  border-color: $focus-border-color;

  #{$dark-background-context} {
    border-color: $focus-border-color;
  }

  #{$light-background-context} {
    border-color: $focus-border-color;
  }
}

.#{$grommet-namespace}form-field--size-large {
  font-size: $paragraph-large-font-size;

  input:not([type]),
  input[type='text'] {
    font-size: $paragraph-large-font-size;
    height: auto;
  }
}

.#{$grommet-namespace}form-field--strong {
  input:not([type]),
  input[type='text'] {
    font-weight: $text-strong-font-weight;
  }
}
