@import '../../brandConfig/colors/colors.scss';
@import '../../brandConfig/font-family/fonts.scss';
html {
  height: 100%;
}

body {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

#root {
  flex: 1;
}

main {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: 'space-between';
}

.container {
  width: 88%;
  margin: 0 auto;
  position: relative;
}

.container--flexbox {
  display: flex;
}

.container--center {
  display: flex;
  justify-content: center;
}

.white-background {
  background-color: #fff;
  padding: 20px 0 80px 0;
}

// modal
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  position: fixed !important;
  z-index: 1003;
}

.react-cookie-banner .button-close {
  background: $brand-color-accent;
  border: 0 !important;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1px 1px $brand-color-accent !important;
  }
}

.ss-modal {
  font-family: $brand-font-family;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -45%;
  max-width: 950px;
  max-height: 500px;
  transform: translate(-50%, -50%);
  z-index: 1002;
}

.ss-modal {
  font-family: $brand-font-family;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -45%;
  max-width: 950px;
  max-height: 500px;
  transform: translate(-50%, -50%);
  z-index: 1002;
}

.ss-modal--auto {
  max-width: 100%;
  max-height: 100%;
}
.ss-modal__buttons-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-column-gap: 20px;
  margin-top: 30px;

  button {
    width: 100% !important;
    height: 40px;
  }
}

// styling cookie banner
.react-cookie-banner {
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 0;
  padding: 15px;
  background: #414142 20px 50% no-repeat;
  color: #fff;
  background-size: 30px 30px;
  font-size: 15px;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  align-items: center;

  .button-close,
  a {
    display: block;
    margin-top: 15px;
    flex-grow: 0;
  }

  a:hover {
    color: #fff !important;
  }

  a.active {
    color: $brand-color !important;
  }

  .button-close {
    color: #fff !important;
    border-color: #fff !important;
    font-weight: 400 !important;
    border-width: 1px !important;

    &:hover {
      font-weight: 700 !important;
    }
  }
}

// global styles
.ss-top-notification {
  margin-bottom: 50px;
}

.ss-list-header {
  text-transform: uppercase;
  font-size: 19px !important;
  margin: 0 0 13px 0;
}
@media screen and (max-width: 768px) {
  .ss-modal--auto {
    width: 100%;
    height: 100%;
  }
}
.table-header {
  font-weight: 300;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 11px 12px;
  text-align: left;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.5;
  color: #333;
}

.ss-modal--auto {
  max-width: 100%;
  max-height: 100%;
}

.table-header {
  font-weight: 300;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 11px 12px;
  text-align: left;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.5;
  color: #333;
}

@media screen and (max-width: 768px) {
  .ss-modal--auto {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .ss-modal__buttons-container {
    display: block;
    * {
      margin-bottom: 10px;
    }
    button {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
}
@media screen and (min-width: 768px) {
  .container {
    width: 90%;
  }

  .white-background {
    padding-top: 30px;
    margin-right: 5%;
  }

  .react-cookie-banner {
    flex-direction: row;
    padding: 20px;

    .button-close {
      margin-left: 30px;
    }
  }

  .ss-modal {
    min-width: 500px;
  }

  .ss-modal--no-min-width {
    min-width: 0;
  }
}

@media screen and (min-width: 1313px) {
  .container {
    max-width: 1180px;
  }

  .white-background {
    margin-right: calc((100% - 1180px) / 2);
  }

  .white-background .container {
    margin-left: calc((100% - 1180px));
  }
}

@media screen and (min-width: 1441px) {
  .container {
    width: 82%;
  }
}
