.single_scheduled_class {
  min-height: 44px;
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 34% 33% 2%;
  align-items: flex-start;
  justify-content: space-between;

  &__label {
    font-weight: 700;
    padding-right: 5px;
  }

  &__name {
    font-size: 18px;
  }

  &__description {
    font-style: italic;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 555px) {
    grid-template-columns: 1fr;

    > div {
      margin: 1em 0;
    }
  }
}

.single_schedule_class__schedule {
  &__item {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: space-between;

    &__day {
      font-weight: bold;
    }

    &__times {
      text-align: left;
    }
  }
}

.single_schedule_class__delete {
  justify-items: flex-end;
}

.single_schedule_class__icon {
  padding-bottom: 10px;
}

@media screen and (min-width: 555px) {
  .single_scheduled_class {
    padding: auto;
  }
}
