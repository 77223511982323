.container {
  min-width: 650px;
  display: grid;
  grid-template-columns: 55% 40%;
  grid-gap: 5%;
  justify-content: space-between;
}

.endTimeError {
  font-size: 12px;
  color: #ca0e0b;
}

@media screen and (max-width: 700px) {
  .container {
    display: inline;
    width: 100%;
  }
}
