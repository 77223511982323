@import '../../brandConfig/colors/colors.scss';
.tertiary {
  color: white !important;
  background: $brand-color !important;
}

.root {
  .grommetux-button__icon {
    padding: 0 !important;
  }
  button > svg {
    margin: 0 auto;
    justify-items: center;
    align-items: center;
    padding: 0 !important;
  }
  @media screen and (max-width: 500px) {
    button {
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
    }
  }
}
