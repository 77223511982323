.videoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listHeader {
  margin-bottom: 0;
}

.videoModal {
  min-width: 70%;
  max-height: 700px;
}

.buttonBar {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
