.root {
  width: 100%;

  :global {
    .grommetux-check-box,
    .grommetux-check-box__control {
      margin-right: 2px !important;
    }
  }

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0px;
    padding: 0px;
    width: 100%;
    table-layout: fixed;
    text-align: left;
  }

  table th,
  table td {
    border: 1px solid #ddd;
    padding: 5px;
    font-weight: normal;
    text-align: center;
  }

  thead {
    background: #ccc;

    th {
      font-weight: bold !important;
      text-align: center;
    }
  }

  th:first-child {
    background: #ccc;
    width: 65px;
  }
}

.checkboxContainer {
  display: block;
  justify-content: center;
}
