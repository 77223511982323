.ClassPassReport {
  &__total {
    &__label {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  &__list {
    &__header {
      display: flex;
      justify-content: space-between;

      font-weight: bold;
    }

    &__item {
      display: grid;
      grid-template-columns: 1fr 25px 20px;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }
  }

  &__filter {
    border: solid 0.5px rgba(0, 0, 0, 0.15);
    padding: 1em;
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    h4 {
      margin-bottom: 0.3em;
    }
  }

  &__classType {
    padding-top: 10px;

    &__checkbox {
      display: block;
      padding-top: 5px;
    }
  }
}

.filterPass {
  display: grid;
  grid-template-columns: 1fr 35px;
  align-items: center;
  justify-content: space-between;
}

.refreshIcon {
  cursor: pointer;
  margin: 0 0 0 auto;
}

@media screen and (max-width: 850px) {
  .ClassPassReport__filter {
    display: block;

    h4 {
      margin-top: 20px;
    }
  }
}
