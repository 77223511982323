@import '../../../../brandConfig/colors/colors.scss';

.tileContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.tileContainerBadge {
  position: absolute;
  top: -12px;
  right: -12px;
  background: lighten($brand-color, 50%);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile {
  border: 1px solid lighten($brand-color, 50%) !important;
  background: lighten($brand-color, 50%) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 40px;
  width: 136px;
  height: 136px;
  margin-bottom: 5px;
}

.tileIcon {
  max-width: 90%;
}

.title {
  font-size: 17px;
}

.text {
  font-weight: bolder;
  font-size: 30px;
  text-align: center;
}

@media (min-width: 768px) {
  .tile {
    width: 180px;
    height: 180px;
  }

  .title {
    font-size: 18px;
  }
  .text {
    font-size: 25px !important;
  }
}

@media (min-width: 1024px) {
  .tileContainer {
    margin-right: 50px;

    &:nth-child(4n) {
      margin-right: 0;
    }

    .text {
      font-size: 25px !important;
    }
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 85%;
  }
  .text {
    font-size: 25px !important;
  }
}
