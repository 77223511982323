// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}world-map {
  width: 100%;

  // IE11 fix world map height
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // The padding value below comes from svg height / width
    padding-bottom: 49%; // 460 / 940 = .489361702 rounding off to 49
    height: 1px;
  }
}

.#{$grommet-namespace}world-map__container {
  position: relative;
  width: 100%;
}

.#{$grommet-namespace}world-map__flag {
  position: absolute;
  top: 0px;
  left: 0px;
}

.#{$grommet-namespace}world-map__continent,
.#{$grommet-namespace}world-map__place {
  @include graph-stroke-color();
  stroke-linecap: round;
  transition: stroke-width $base-animation-duration;
  outline: none;
}

.#{$grommet-namespace}world-map__continent {
  stroke-width: 6px;
}

.#{$grommet-namespace}world-map__place {
  stroke-width: 8px;
}

.#{$grommet-namespace}world-map__continent--active {
  stroke-width: 8px;
  cursor: pointer;
}

.#{$grommet-namespace}world-map__place--active {
  stroke-width: 20px;
  cursor: pointer;
}
