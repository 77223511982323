.mainContainer {
  padding-top: 5rem;
  height: calc(100% - 293px);
}

@media (min-width: 481px) {
  .mainContainer {
    height: calc(100% - 77px);
  }
}

@media (min-width: 870px) {
  .mainContainer {
    height: calc(100% - 108px);
  }
}

@media (min-width: 785px) {
  .mainContainer {
    height: calc(100% - 77px);
  }
}
