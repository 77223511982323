// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}distribution {
  position: relative;
  width: 100%;
}

.#{$grommet-namespace}distribution__graphic {
  // We position the SVG absolutely so we can size it based on the container
  // without it influcing the size itself.
  position: absolute;
  top: 0px;
  left: 0px;
  outline: none;
  max-width: 100%;
}

.#{$grommet-namespace}distribution__graphic--focus {
  border-color: $focus-border-color;
  box-shadow: 0 0 1px 1px $focus-border-color;
}

.#{$grommet-namespace}distribution__background {
  fill: $secondary-background-color;

  #{$dark-background-context} {
    fill: $colored-active-background-color;
  }

  #{$light-background-context} {
    fill: $secondary-background-color;
  }
}

.#{$grommet-namespace}distribution__item--clickable {
  cursor: pointer;
  outline: none;
}

.#{$grommet-namespace}distribution__item-box {
  @include graph-fill-color();
  transition: all 0.2s ease;
}

.#{$grommet-namespace}distribution__item-icons {
  @include graph-stroke-color();
}

.#{$grommet-namespace}distribution__label {
  position: absolute;
  background-color: transparent;
  padding: quarter($inuit-base-spacing-unit) halve($inuit-base-spacing-unit);
  font-family: $brand-large-number-font-family;
  overflow: hidden;
  text-align: left;
  pointer-events: none;
  @include overlayed-text-color();
  transition: all 0.2s ease;
}

.#{$grommet-namespace}distribution__label-value {
  display: block;
  @include inuit-font-size($large-number-font-size);
  font-weight: $text-strong-font-weight;
}

.#{$grommet-namespace}distribution__label-units {
  @include inuit-font-size($h3-font-size, inherit);
  margin-left: quarter($inuit-base-spacing-unit);
  font-weight: $text-font-weight;
}

.#{$grommet-namespace}distribution__label-label {
  display: block;
}

.#{$grommet-namespace}distribution__label--active {
  z-index: 10;
  color: $text-color;
  border-right: 2px solid $secondary-background-color;
  border-left: 2px solid $secondary-background-color;
  opacity: 0.9;
}

.#{$grommet-namespace}distribution__label--thin {
  .#{$grommet-namespace}distribution__label-value,
  .#{$grommet-namespace}distribution__label-label {
    display: inline-block;
  }
}

.#{$grommet-namespace}distribution__label--small {
  // padding: 0 halve($inuit-base-spacing-unit)
  //   halve($inuit-base-spacing-unit) quarter($inuit-base-spacing-unit);

  .#{$grommet-namespace}distribution__label-value,
  .#{$grommet-namespace}distribution__label-units {
    @include inuit-font-size($small-large-number-font-size, 1);
    margin-right: 4px;
  }
}

.#{$grommet-namespace}distribution__label--icons {
  padding: 0 halve($inuit-base-spacing-unit) halve($inuit-base-spacing-unit) 0;
  background-color: $active-background-color;
  color: $text-color;

  #{$dark-background-context} {
    background-color: $colored-active-background-color;
    color: $colored-text-color;
  }

  #{$light-background-context} {
    background-color: $active-background-color;
    color: $text-color;
  }

  .label-value {
    line-height: 1;
  }

  .label-units {
    color: $secondary-text-color;
  }

  .label-label {
    display: block;
  }
}

.#{$grommet-namespace}distribution__loading-indicator {
  stroke-width: $inuit-base-spacing-unit;
  @include graph-stroke-color();
}

.#{$grommet-namespace}distribution--icons {
  .#{$grommet-namespace}distribution__label {
    padding: 0 halve($inuit-base-spacing-unit) halve($inuit-base-spacing-unit) 0;
  }

  .#{$grommet-namespace}distribution__label-value {
    line-height: 1;
  }
}

.#{$grommet-namespace}distribution--small {
  height: $size-small;
}

.#{$grommet-namespace}distribution--medium {
  height: $size-medium;
}

.#{$grommet-namespace}distribution--large {
  height: $size-large;
}

.#{$grommet-namespace}distribution--full {
  height: 100%;
  flex: 1 1;

  .#{$grommet-namespace}distribution__graphic {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
}
