.main-details-container {
  border-top: 1px solid rgba(0, 0, 0, 0.15);

  &.admin-user-profile-pic > div {
    margin-right: 0;
  }

  .main-details-container-phone {
    display: block;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.admin-user-attandance-button {
  margin-top: 15px;
  float: right;
  // max-width: 100%;
}
