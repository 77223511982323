.videoMenu {
  margin: 2rem 0;
  gap: 1rem;
}

.filterBar {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  padding: 1rem 0;
}

.tableView {
  padding-top: 1rem;
}

.actionCell {
  display: flex;
  justify-content: flex-end;
}

.viewActionBar {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.completedVideo {
  margin: 1rem 0;
}
