.supersonicLogo {
  max-height: 60px;
  height: auto;
  display: inline-block;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    align-self: center;
  }
  @media screen and (min-width: 768px) {
    display: inline-flex;
  }

  @media screen and (max-width: 1000px) {
    max-width: 80% !important;
    height: auto !important;
  }
}
