// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}tbd {
  text-align: center;
  padding: quadruple($inuit-base-spacing-unit);
  @include inuit-font-size(quadruple($inuit-base-spacing-unit));
  font-style: italic;
  background-color: $border-color;
  color: $background-color;
}
