// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}footer {
  min-height: $footer-height;
  width: 100%;
}

.#{$grommet-namespace}footer--small {
  min-height: $small-footer-height;
}

.#{$grommet-namespace}footer--large {
  min-height: $large-footer-height;
}

.#{$grommet-namespace}footer__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: $inuit-base-spacing-unit;
  padding-right: $inuit-base-spacing-unit;

  > * {
    margin-right: double($inuit-base-spacing-unit);

    &:last-child {
      margin-right: 0px;
      text-align: left;
    }
  }
}

.#{$grommet-namespace}footer--primary {
  height: auto;
  padding: $inuit-base-spacing-unit;

  .#{$grommet-namespace}footer__content {
    position: relative;
    color: $secondary-text-color;
    display: block;
  }
}

.#{$grommet-namespace}footer--centered {
  .#{$grommet-namespace}footer__content {
    display: block;
    text-align: center;

    > * {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
  }
}

.#{$grommet-namespace}footer--flush {
  .#{$grommet-namespace}footer__content,
  .#{$grommet-namespace}footer__wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.#{$grommet-namespace}footer--fixed {
  .#{$grommet-namespace}footer__wrapper {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 3;
  }

  .#{$grommet-namespace}footer__wrapper--fill {
    .#{$grommet-namespace}footer__wrapper {
      background-color: $footer-background-color;
    }
  }
}

.#{$grommet-namespace}footer--fixed.#{$grommet-namespace}footer--primary {
  .#{$grommet-namespace}footer__wrapper {
    position: fixed;
  }

  .#{$grommet-namespace}footer__wrapper--fill {
    .#{$grommet-namespace}footer__wrapper {
      background-color: $footer-background-color;
    }
  }

  .#{$grommet-namespace}footer__content {
    position: static;
    background-color: transparent;
  }
}

.#{$grommet-namespace}footer__container {
  flex-shrink: 0;
}

.#{$grommet-namespace}footer__container--float {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.#{$grommet-namespace}footer__container--fill {
  .#{$grommet-namespace}footer {
    background-color: $footer-background-color;
  }
}

.#{$grommet-namespace}footer__container--fixed {
  position: relative;
  width: 100%;

  .#{$grommet-namespace}footer__wrapper {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 3;
  }
}

.#{$grommet-namespace}footer__wrapper {
  height: $footer-height;
}

.#{$grommet-namespace}footer__wrapper--large {
  height: $large-footer-height;
}

.#{$grommet-namespace}footer__wrapper--small {
  height: $small-footer-height;
}

*:not(.#{$grommet-namespace}footer__container--float) > .#{$grommet-namespace}footer--float {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
