@import '../../brandConfig/colors/colors.scss';

.root {
  width: 100%;

  h4 {
    margin-bottom: 0;
  }
}

.checkboxes {
  padding: 20px 0 20px 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  background: #fafafa;
}

@media screen and (max-width: 830px) {
  .checkboxes {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .checkboxes {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 400px) {
  .checkboxes {
    display: block;
  }
}
