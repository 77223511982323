.root {
  @media screen and (max-width: 870px) {
    display: none;
  }
}

.icon {
  :hover {
    fill: #666 !important;
  }
}
