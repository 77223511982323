@import '../../../brandConfig/colors/colors.scss';
.addNewButton {
  display: flex;
  justify-content: space-between;
  button {
    width: 201px;
  }
}

.filtersContainer {
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 60px;
  grid-column-gap: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 10px 10px;

  h4 {
    margin: 0 0 10px 0;
  }
  input {
    background: white;
    width: 100%;
    margin: 0;
  }
}
.clearFilters {
  margin-top: 42% !important;
}

.checkbox {
  display: block;
  margin: 5px;
}

.classNameFilter {
  height: 48px;
  margin-bottom: 10px;
}

.gymSelector {
  button {
    width: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .filtersContainer {
    display: inline-block;
    width: 100%;
  }
}
