.client-profile__buttons-container {
  margin-top: 100px;
}

.client-profile__smaller-buttons-container {
  display: flex;
  flex-direction: column;
}

.client-profile__button,
.client-profile__qr-button,
.client-profile__delete-button {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.client-profile__delete-button {
  color: #fff !important;
  background-color: #d0021b !important;
  border-color: #d0021b !important;
  width: 100%;

  &:hover {
    box-shadow: 0px 0px 0px 2px #d0021b !important;
  }
}

@media (min-width: 768px) {
  .client-profile__smaller-buttons-container {
    flex-direction: row;
    justify-content: space-between;
  }
}
