.link {
  color: #fff !important;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 700;
  line-height: 31px;
}

.linkLight {
  font-weight: 400;
  font-size: 16px;
  text-transform: initial;
  white-space: nowrap;
  margin-bottom: 16px;
}

.linkDark {
  color: #4e443d !important;
}

@media screen and (min-width: 768px) {
  .link:not(.linkLight) {
    margin-right: 32px;
    line-height: initial;
    font-size: initial;

    &:nth-of-type(2) {
      margin-right: 0;
    }
  }

  .linkLight {
    margin-right: 32px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 885px) {
  .link:not(.linkLight) {
    margin-right: 40px;
  }
}
