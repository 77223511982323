.trainerError {
  font-size: 14px;
  color: #ca0e0b;
}

.root {
  margin-bottom: 20px;
}

.container {
  display: grid;
  grid-column-gap: 5px;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
