.upcomingClassesBox {
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.upcomingClassesBoxNoMargin {
  margin-bottom: 0;
}

.info__text {
  margin-bottom: 12px;
}

.singleClass {
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .upcomingClassesBox {
    padding: 0;
    align-items: center;
    flex-direction: row;
  }

  .info__text,
  .singleClass {
    margin-bottom: 0;
  }
}
