.text {
  text-align: center;
  font-size: 1.2em;
  margin: 10px 0;
}

.classesList {
  margin-bottom: 40px !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
