.filters {
  border: solid 1px rgba(0, 0, 0, 0.15) !important;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 630px) {
  .filters {
    display: block;
  }
}
