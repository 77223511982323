.listHeader {
  font-size: 19px !important;
  margin: 0 0 13px 0;
  text-transform: uppercase;
}

.header {
  display: grid;
  grid-template-columns: auto 250px;
  align-items: center;
}

.clientTable {
  th {
    &:last-child {
      width: 100px;
    }
  }
}

@media screen and (max-width: 450px) {
  .header {
    display: block;
  }
}
