.dashboardContainer {
  max-width: 888px;
  margin: 0 auto;
}

.productContainer {
  padding: 10px 0px;
  border-bottom: 5px solid #80b91e;
}

.tileBoard {
  margin: 40px 0px;
}

h3 {
  margin-top: 15px;
  margin-bottom: 0px;
}
