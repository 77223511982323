// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

$small-pad-lap: halve($inuit-base-spacing-unit);
$medium-pad-lap: $inuit-base-spacing-unit;
$large-pad-lap: double($inuit-base-spacing-unit);
$xlarge-pad-lap: round($inuit-base-spacing-unit * 8);

$small-pad-palm: quarter($inuit-base-spacing-unit);
$medium-pad-palm: halve($inuit-base-spacing-unit);
$large-pad-palm: $inuit-base-spacing-unit;
$xlarge-pad-palm: double($inuit-base-spacing-unit);

@mixin pad {
  &--pad-none {
    padding: 0px;
  }

  @include media-query(lap-and-up) {
    &--pad-small {
      padding: $small-pad-lap;
    }

    &--pad-medium {
      padding: $medium-pad-lap;
    }

    &--pad-large {
      padding: $large-pad-lap;
    }

    &--pad-xlarge {
      padding: $xlarge-pad-lap;
    }
  }

  @include media-query(palm) {
    &--pad-small {
      padding: $small-pad-palm;
    }

    &--pad-medium {
      padding: $medium-pad-palm;
    }

    &--pad-large {
      padding: $large-pad-palm;
    }

    &--pad-xlarge {
      padding: $xlarge-pad-palm;
    }
  }

  &--pad-horizontal-none {
    padding-left: 0px;
    padding-right: 0px;
  }

  @include media-query(lap-and-up) {
    &--pad-horizontal-small {
      padding-left: $small-pad-lap;
      padding-right: $small-pad-lap;
    }

    &--pad-horizontal-medium {
      padding-left: $medium-pad-lap;
      padding-right: $medium-pad-lap;
    }

    &--pad-horizontal-large {
      padding-left: $large-pad-lap;
      padding-right: $large-pad-lap;
    }

    &--pad-horizontal-xlarge {
      padding-left: $xlarge-pad-lap;
      padding-right: $xlarge-pad-lap;
    }
  }

  @include media-query(palm) {
    &--pad-horizontal-small {
      padding-left: $small-pad-palm;
      padding-right: $small-pad-palm;
    }

    &--pad-horizontal-medium {
      padding-left: $medium-pad-palm;
      padding-right: $medium-pad-palm;
    }

    &--pad-horizontal-large {
      padding-left: $large-pad-palm;
      padding-right: $large-pad-palm;
    }

    &--pad-horizontal-xlarge {
      padding-left: $xlarge-pad-palm;
      padding-right: $xlarge-pad-palm;
    }
  }

  &--pad-vertical-none {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @include media-query(lap-and-up) {
    &--pad-vertical-small {
      padding-top: $small-pad-lap;
      padding-bottom: $small-pad-lap;
    }

    &--pad-vertical-medium {
      padding-top: $medium-pad-lap;
      padding-bottom: $medium-pad-lap;
    }

    &--pad-vertical-large {
      padding-top: $large-pad-lap;
      padding-bottom: $large-pad-lap;
    }

    &--pad-vertical-xlarge {
      padding-top: $xlarge-pad-lap;
      padding-bottom: $xlarge-pad-lap;
    }
  }

  @include media-query(palm) {
    &--pad-vertical-small {
      padding-top: $small-pad-palm;
      padding-bottom: $small-pad-palm;
    }

    &--pad-vertical-medium {
      padding-top: $medium-pad-palm;
      padding-bottom: $medium-pad-palm;
    }

    &--pad-vertical-large {
      padding-top: $large-pad-palm;
      padding-bottom: $large-pad-palm;
    }

    &--pad-vertical-xlarge {
      padding-top: $xlarge-pad-palm;
      padding-bottom: $xlarge-pad-palm;
    }
  }
}
