.filters {
  border: solid 1px rgba(0, 0, 0, 0.15) !important;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin-bottom: 20px;
}

.item {
  display: grid;
  grid-template-columns: 1fr 1fr 40px;
  grid-gap: 20px;
  align-items: center;
  padding: 7px 0;
  grid-template-areas: 'column-1 column-2 column-3';
  border-bottom: 2px solid #dddddd;

  svg {
    height: 50px !important;
    width: 50px !important;
  }
}

@media screen and (max-width: 600px) {
  .filters {
    display: block;
  }
  .item {
    display: grid;
    grid-template-columns: 1fr 40px;
    grid-template-areas: 'column-1 column-2 ';
    grid-row-gap: 0;

    :nth-child(1) {
      font-weight: bold;
      font-size: 20px;
    }

    :nth-child(3) {
      grid-area: column-2;
      align-items: center;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }
}
