// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}legend {
  text-align: left;
  white-space: normal;
  display: inline-block;
  line-height: $inuit-base-spacing-unit;

  html.rtl & {
    text-align: right;
  }
}

.#{$grommet-namespace}legend__item,
.#{$grommet-namespace}legend__total {
  color: $secondary-text-color;

  #{$dark-background-context} {
    color: $inactive-colored-text-color;
  }

  #{$light-background-context} {
    color: $secondary-text-color;
  }
}

.#{$grommet-namespace}legend__item-label {
  margin-right: halve($inuit-base-spacing-unit);
}

.#{$grommet-namespace}legend__item-units,
.#{$grommet-namespace}legend__total-units {
  display: inline-block;
  margin-left: quarter($inuit-base-spacing-unit);

  html.rtl & {
    margin-left: 0;
    margin-right: quarter($inuit-base-spacing-unit);
  }
}

.#{$grommet-namespace}legend__item-swatch {
  width: halve($inuit-base-spacing-unit);
  height: halve($inuit-base-spacing-unit);
  // margin-top: quarter($inuit-base-spacing-unit);
  margin-right: halve($inuit-base-spacing-unit);
  overflow: visible;

  @include graph-stroke-color();

  html.rtl & {
    margin-right: 0;
    margin-left: halve($inuit-base-spacing-unit);
  }

  path {
    stroke-width: halve($inuit-base-spacing-unit);
    transition-property: stroke-width;
    transition-duration: $base-animation-duration;
    transition-timing-function: ease-in-out;
  }
}

.#{$grommet-namespace}legend__item--clickable {
  cursor: pointer;
}

.#{$grommet-namespace}legend__item--active {
  color: $text-color;

  svg.#{$grommet-namespace}legend__item-swatch {
    path {
      stroke-width: halve($inuit-base-spacing-unit);
    }
  }

  #{$dark-background-context} {
    color: $active-colored-text-color;
  }

  #{$light-background-context} {
    color: $text-color;
  }
}

// need li to override .grommet li
.#{$grommet-namespace}legend__total {
  margin-left: $inuit-base-spacing-unit;
  margin-top: quarter($inuit-base-spacing-unit);
  padding-top: quarter($inuit-base-spacing-unit);
  border-top: 1px dotted $border-color;

  #{$dark-background-context} {
    border-color: $colored-border-color;
  }

  #{$light-background-context} {
    border-color: $border-color;
  }

  html.rtl & {
    margin-left: 0;
    margin-right: $inuit-base-spacing-unit;
  }
}

.#{$grommet-namespace}legend--large {
  font-size: $paragraph-large-font-size;
  line-height: $paragraph-large-font-size + round($inuit-base-spacing-unit / 3);

  .#{$grommet-namespace}legend__item {
    margin-bottom: quarter($inuit-base-spacing-unit);
  }

  .#{$grommet-namespace}legend__item-label {
    margin-right: $inuit-base-spacing-unit;
  }

  .#{$grommet-namespace}legend__item-swatch {
    position: relative;
    top: 3px;
    width: $inuit-base-spacing-unit;
    height: $inuit-base-spacing-unit;
  }

  .#{$grommet-namespace}legend__total {
    margin-left: round($inuit-base-spacing-unit * 1.5);
  }
}
