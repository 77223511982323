.filter {
  margin-bottom: 1em;

  input {
    background: white;
  }

  h4 {
    margin-bottom: 0.3em !important;
  }

  input {
    width: 100%;
  }
}

@media screen and (max-width: 678px) {
  .filter input {
    width: 100%;
  }
}
