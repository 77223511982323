.root {
  margin-bottom: 16px;

  h4 {
    margin-bottom: 0.3em;
  }
}

.container {
  display: grid;
  grid-column-gap: 5px;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
