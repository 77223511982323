// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}text-input {
  @include input();
}

.#{$grommet-namespace}text-input--active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.#{$grommet-namespace}text-input__suggestions {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin: 0px;
  list-style-type: none;
}

.#{$grommet-namespace}text-input__suggestion {
  padding: quarter($inuit-base-spacing-unit) $inuit-base-spacing-unit;
  cursor: pointer;

  &:hover,
  &--active {
    background-color: $hover-background-color;
  }
}
