@import '../../../../brandConfig/colors/colors.scss';

.thumbnail {
  display: flex;
  flex-direction: column;
  width: 220px;
  max-width: 16rem;
  padding: 3px;
}

.thumbnailImg {
  position: relative;
  height: 80%;
}

.locked > .thumbnailImg > img {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.content {
  & h4 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.watched {
  opacity: 0.75;
}

.locked {
  pointer-events: none;
}

.lockIcon {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $brand-color;
  fill: $brand-color;
  stroke: $brand-color;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.picker {
  cursor: pointer;
  padding: 2px;
}

.selected {
  outline: 3px solid $brand-color;
}

.actionBar {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: flex-end;
  border-top: 1px solid $brand-color;
  margin-top: 0.5rem;
}

.buttonBar {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-top: rgb(145, 145, 145) solid 1px;
}

.spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  text-decoration: none !important;
}
