.container {
  padding-top: 2rem;
}

.explainer {
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
  gap: 0.5rem;
}
