.formPhotoContainer {
  display: flex;
  flex-direction: column;
}

.profilePictureInfo {
  font-size: 10px;
  max-width: 190px;
  text-align: center;
  margin: 0 auto 50px auto;
}

@media (min-width: 768px) {
  .formPhotoContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .profilePictureInfo {
    margin-left: auto;
    margin-right: auto;
  }
}
