// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}collapsible {
  overflow: hidden;
}

.#{$grommet-namespace}collapsible__wrapper {
  width: 100%;
}

.#{$grommet-namespace}collapsible.animate {
  transition: height 0.5s ease-out;
}
