.flexContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.flexContainer > div {
  margin: 6px auto;
}

.calendarHeader {
  justify-content: space-between;
  margin-bottom: 10px;

  .trainerContainer {
    display: flex;
    align-items: center;

    .resetTrainer {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}

.calendarDate {
  display: flex;
  align-items: center;

  .date {
    margin: 0 10px;
    font-size: 16px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
  }
}

.datePicker {
  input {
    display: none;
  }

  button {
    margin-right: 10px;
    border: none !important;
    box-shadow: none !important;
    position: initial;
    transform: none;
  }
}
