.formContainer {
  margin: 0 auto;
  max-width: 780px;
}

.input {
  margin-bottom: 20px !important;
}

.label {
  display: block;
  margin: 0 0 4px 0 !important;
}

.button {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.additionalOptions {
  display: flex;
  flex-direction: column;
}

.spinnerContainer {
  text-align: center;
}

.notification {
  margin-bottom: 50px;
}

@media screen and (min-width: 768px) {
  .additionalOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
