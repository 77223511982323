.filters {
  border: solid 1px rgba(0, 0, 0, 0.15) !important;
  padding: 5px 20px 15px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 20px;

  h4 {
    margin-top: 15px;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.radioButtonContainer {
  margin-top: 20px;
  display: block;
}

.rightColumn {
  margin-top: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 100px 100px 100px 100px 100px 100px;
  grid-column-gap: 10px;
  padding: 10px;
  align-items: center;
  overflow-wrap: break-word;
  border-radius: 10px;

  span {
    display: none;
  }
}

.singleClass {
  :hover {
    background: #f5f5f5;
    cursor: pointer;
    border-radius: 10px;
  }
}

.attendance {
  text-align: center;
}

.header {
  margin-top: 20px;
}

@media screen and (max-width: 720px) {
  .filters {
    display: block;
  }
  .header {
    visibility: hidden;
    height: 0;
    margin-top: 0px;
  }
  .grid {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 15px;
    background: #dddddd;

    :first-child {
      grid-column: 1 / -1;
      font-weight: bold;
      justify-content: left;
    }

    span {
      display: inline;
    }
  }
  .attendance {
    text-align: left;
  }
  .name {
    font-size: 20px;
    margin: 5px 0;
  }
}

@media screen and (max-width: 500px) {
  .container {
    display: block;
  }
  .grid {
    display: block;
  }
}
