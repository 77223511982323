// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

@mixin vertical-margin {
  &--margin-none {
    margin-top: 0;
    margin-bottom: 0;
  }

  &--margin-small {
    margin-top: halve($inuit-base-spacing-unit);
    margin-bottom: halve($inuit-base-spacing-unit);
  }

  &--margin-medium {
    margin-top: $inuit-base-spacing-unit;
    margin-bottom: $inuit-base-spacing-unit;
  }

  &--margin-large {
    margin-top: double($inuit-base-spacing-unit);
    margin-bottom: double($inuit-base-spacing-unit);
  }
}

@mixin margin {
  &--margin-none {
    margin: 0px;
  }

  &--margin-small {
    margin: halve($inuit-base-spacing-unit);
  }

  &--margin-medium {
    margin: $inuit-base-spacing-unit;
  }

  &--margin-large {
    margin: double($inuit-base-spacing-unit);
  }

  @include media-query(palm) {
    &--margin-small {
      margin: quarter($inuit-base-spacing-unit);
    }

    &--margin-medium {
      margin: halve($inuit-base-spacing-unit);
    }

    &--margin-large {
      margin: $inuit-base-spacing-unit;
    }
  }

  &--margin-horizontal-none {
    margin-left: 0px;
    margin-right: 0px;
  }

  &--margin-horizontal-small {
    margin-left: halve($inuit-base-spacing-unit);
    margin-right: halve($inuit-base-spacing-unit);
  }

  &--margin-horizontal-medium {
    margin-left: $inuit-base-spacing-unit;
    margin-right: $inuit-base-spacing-unit;
  }

  &--margin-horizontal-large {
    margin-left: double($inuit-base-spacing-unit);
    margin-right: double($inuit-base-spacing-unit);
  }

  @include media-query(palm) {
    &--margin-horizontal-small {
      margin-left: quarter($inuit-base-spacing-unit);
      margin-right: quarter($inuit-base-spacing-unit);
    }

    &--margin-horizontal-medium {
      margin-left: halve($inuit-base-spacing-unit);
      margin-right: halve($inuit-base-spacing-unit);
    }

    &--margin-horizontal-large {
      margin-left: $inuit-base-spacing-unit;
      margin-right: $inuit-base-spacing-unit;
    }
  }

  &--margin-vertical-none {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &--margin-vertical-small {
    margin-top: halve($inuit-base-spacing-unit);
    margin-bottom: halve($inuit-base-spacing-unit);
  }

  &--margin-vertical-medium {
    margin-top: $inuit-base-spacing-unit;
    margin-bottom: $inuit-base-spacing-unit;
  }

  &--margin-vertical-large {
    margin-top: double($inuit-base-spacing-unit);
    margin-bottom: double($inuit-base-spacing-unit);
  }

  @include media-query(palm) {
    &--margin-vertical-small {
      margin-top: quarter($inuit-base-spacing-unit);
      margin-bottom: quarter($inuit-base-spacing-unit);
    }

    &--margin-vertical-medium {
      margin-top: halve($inuit-base-spacing-unit);
      margin-bottom: halve($inuit-base-spacing-unit);
    }

    &--margin-vertical-large {
      margin-top: $inuit-base-spacing-unit;
      margin-bottom: $inuit-base-spacing-unit;
    }
  }

  &--margin-left-none {
    margin-left: 0px;
  }

  &--margin-left-small {
    margin-left: halve($inuit-base-spacing-unit);
  }

  &--margin-left-medium {
    margin-left: $inuit-base-spacing-unit;
  }

  &--margin-left-large {
    margin-left: double($inuit-base-spacing-unit);
  }

  @include media-query(palm) {
    &--margin-left-small {
      margin-left: quarter($inuit-base-spacing-unit);
    }

    &--margin-left-medium {
      margin-left: halve($inuit-base-spacing-unit);
    }

    &--margin-left-large {
      margin-left: $inuit-base-spacing-unit;
    }
  }

  &--margin-right-none {
    margin-right: 0px;
  }

  &--margin-right-small {
    margin-right: halve($inuit-base-spacing-unit);
  }

  &--margin-right-medium {
    margin-right: $inuit-base-spacing-unit;
  }

  &--margin-right-large {
    margin-right: double($inuit-base-spacing-unit);
  }

  @include media-query(palm) {
    &--margin-right-small {
      margin-right: quarter($inuit-base-spacing-unit);
    }

    &--margin-right-medium {
      margin-right: halve($inuit-base-spacing-unit);
    }

    &--margin-right-large {
      margin-right: $inuit-base-spacing-unit;
    }
  }

  &--margin-top-none {
    margin-top: 0px;
  }

  &--margin-top-small {
    margin-top: halve($inuit-base-spacing-unit);
  }

  &--margin-top-medium {
    margin-top: $inuit-base-spacing-unit;
  }

  &--margin-top-large {
    margin-top: double($inuit-base-spacing-unit);
  }

  @include media-query(palm) {
    &--margin-top-small {
      margin-top: quarter($inuit-base-spacing-unit);
    }

    &--margin-top-medium {
      margin-top: halve($inuit-base-spacing-unit);
    }

    &--margin-top-large {
      margin-top: $inuit-base-spacing-unit;
    }
  }

  &--margin-bottom-none {
    margin-bottom: 0px;
  }

  &--margin-bottom-small {
    margin-bottom: halve($inuit-base-spacing-unit);
  }

  &--margin-bottom-medium {
    margin-bottom: $inuit-base-spacing-unit;
  }

  &--margin-bottom-large {
    margin-bottom: double($inuit-base-spacing-unit);
  }

  @include media-query(palm) {
    &--margin-bottom-small {
      margin-bottom: quarter($inuit-base-spacing-unit);
    }

    &--margin-bottom-medium {
      margin-bottom: halve($inuit-base-spacing-unit);
    }

    &--margin-bottom-large {
      margin-bottom: $inuit-base-spacing-unit;
    }
  }
}
