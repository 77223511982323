.loginContainer {
  margin: 0 auto;
  max-width: 780px;
}

.input {
  margin-bottom: 20px !important;
  width: 100% !important;
}

.label {
  display: block;
  margin: 0 0 4px 0 !important;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 20px;
  }
}

.loginButton {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.additionalOptions {
  display: flex;
  flex-direction: column;
}

.remeberMeCheckbox {
  margin-bottom: 16px;
}

.forgotPasswordLink {
  margin-bottom: 55px;
}

.spinnerContainer {
  text-align: center;
}

.notifcation {
  margin-bottom: 50px;
}

@media screen and (min-width: 768px) {
  .additionalOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
