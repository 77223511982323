.root {
  display: flex;
  justify-content: center;

  h3 {
    margin-bottom: 0;
  }
}

.root > div {
  margin: 0 10px;
}

.errorMsg {
  font-weight: bold;
  color: red;
  text-align: center;
}

.mainError {
  margin: 8px 0 0 0;
  font-size: 20px;
  height: 40px;
}
