// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.grommet {
  h1:not(.#{$grommet-namespace}heading) {
    @include h1();
  }

  h2:not(.#{$grommet-namespace}heading) {
    @include h2();
  }

  h3:not(.#{$grommet-namespace}heading) {
    @include h3();
  }

  h4:not(.#{$grommet-namespace}heading) {
    @include h4();
  }

  h5:not(.#{$grommet-namespace}heading) {
    @include h5();
  }

  h6:not(.#{$grommet-namespace}heading) {
    @include h6();
  }

  h1:not(.#{$grommet-namespace}heading),
  h2:not(.#{$grommet-namespace}heading),
  h3:not(.#{$grommet-namespace}heading),
  h4:not(.#{$grommet-namespace}heading),
  h5:not(.#{$grommet-namespace}heading),
  h6:not(.#{$grommet-namespace}heading) {
    @include heading();
  }

  dd {
    max-width: $readable-text-width;
    margin-left: 0px;
    @include text();
  }

  li:not([class^='#{$grommet-namespace}']) {
    @include text();
  }

  dd {
    font-size: $paragraph-font-size;
    font-weight: $text-font-weight;
    line-height: $paragraph-line-height;
    margin-bottom: halve($inuit-base-spacing-unit);
  }

  p:not(.#{$grommet-namespace}paragraph) {
    @include paragraph();
  }

  blockquote {
    @include inuit-font-size(36px);
    margin-top: $inuit-base-spacing-unit;
    margin-bottom: $inuit-base-spacing-unit;
  }

  b,
  strong {
    font-weight: $text-strong-font-weight;
  }

  code {
    font-family: $code-font-family;
  }

  #{$dark-background} {
    p:not(.#{$grommet-namespace}paragraph),
    dd {
      color: $colored-text-color;
    }
  }

  #{$light-background} {
    p:not(.#{$grommet-namespace}paragraph),
    dd {
      color: $text-color;
    }
  }
}
