@import '../../../brandConfig/colors/colors.scss';

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.uploadFAQ {
  label {
    margin: 0 20px;
    padding: 8px 22px;
    border: 2px solid $brand-color;
    font-weight: bold;

    &:hover {
      cursor: pointer;
    }
  }

  input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

@media screen and (max-width: 600px) {
  .container {
    display: block;

    button {
      width: 100%;
      max-width: 100%;
    }
  }
  .uploadFAQ {
    label {
      display: block;
      text-align: center;
      margin: 10px 0 0 0;
    }
  }
}
