.singleFAQ {
  min-height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;

  p {
    margin: 0 !important;
  }

  h4 {
    margin-bottom: 14px;
  }
}

.icons {
  margin-left: 10px;
}

@media screen and (min-width: 555px) {
  .singleFAQ {
    padding: auto;
  }
}
