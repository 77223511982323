@import '../../brandConfig/colors/colors.scss';

.flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.singleAttendee {
  min-height: 44px;
  padding: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.lateCancellationInfo {
  display: block;
  margin-left: 10px;
  margin-right: 5px;
  font-size: 12px;
  color: #c90e0c;
}

@media screen and (min-width: 555px) {
  .singleAttendee {
    padding: auto;
  }
}

.editClassTime {
  background: lighten($brand-color, 40%);
  padding: 20px 20px 70px 20px;

  .rightSide {
    float: right;
    display: block;
  }
}

.notesContainer {
  padding: 5px 20px !important;
  background: lightgrey;

  span {
    font-weight: bold !important;
  }
}
