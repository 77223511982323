.clientTable {
  th {
    &:last-child {
      width: 100px;
    }
  }
}

@media screen and (max-width: 768px) {
  .report__filter {
    display: block;
  }
}
