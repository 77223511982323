// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.grommet,
.brand-font {
  font-family: $brand-font-family;
}

.grommet {
  // inuit-box-sizing sets in on the 'html' element, we only want it here
  box-sizing: border-box;

  // From inuit-page
  //
  // High-, page-level styling.
  //
  // 1. Set the default `font-size` and `line-height` for the entire project,
  //    sourced from our default variables. The `font-size` is calculated to exist
  //    in ems, the `line-height` is calculated to exist unitlessly.
  // 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
  //    navigating between pages that do/do not have enough content to produce
  //    scrollbars naturally.
  // 4. Prevent certain mobile browsers from automatically zooming fonts.
  // 5. Fonts on OSX will look more consistent with other systems that do not
  //    render text using sub-pixel anti-aliasing.

  font-size: ($inuit-base-font-size / 16px) * 1em;
  line-height: $inuit-base-line-height / $inuit-base-font-size;
  background-color: $background-color;
  color: $text-color;
  // overflow-y: scroll; // [2]
  // min-height: 100%;   // [3]
  -webkit-text-size-adjust: 100%; // [4]
  -ms-text-size-adjust: 100%; // [4]
  -moz-osx-font-smoothing: grayscale; // [5]
  -webkit-font-smoothing: antialiased; // [5]

  * {
    box-sizing: inherit;
  }

  // Right to left context
  &.rtl {
    direction: rtl;
  }

  .large-number-font {
    font-family: $brand-large-number-font-family;
  }

  .secondary {
    color: $secondary-text-color;
  }

  .error {
    color: map-get($brand-status-colors, critical);
  }

  #{$dark-background} {
    .secondary {
      color: $colored-text-color;
    }

    .error {
      color: $brand-error-colored-color;
    }
  }

  #{$light-background} {
    .secondary {
      color: $secondary-text-color;
    }

    .error {
      color: map-get($brand-status-colors, critical);
    }
  }
}
