// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}headline {
  font-weight: $text-font-weight;
  margin-bottom: $inuit-base-spacing-unit;
  max-width: 100%;
  @include text-align();
  @include vertical-margin();

  @include media-query(lap-and-up) {
    @include inuit-font-size($headline-font-size, 1);
  }
  @include media-query(palm) {
    @include inuit-font-size($headline-palm-font-size, 1);
  }
}

.#{$grommet-namespace}headline--small {
  @include inuit-font-size($headline-small-font-size, 1);
}

.#{$grommet-namespace}headline--large {
  @include media-query(lap-and-up) {
    @include inuit-font-size($headline-large-font-size, 1);
  }
  @include media-query(palm) {
    @include inuit-font-size($headline-large-palm-font-size, 1);
  }
}

.#{$grommet-namespace}headline--xlarge {
  @include media-query(lap-and-up) {
    @include inuit-font-size($headline-xlarge-font-size, 1);
  }
  @include media-query(palm) {
    @include inuit-font-size($headline-xlarge-palm-font-size, 1);
  }
}

.#{$grommet-namespace}headline--strong {
  font-weight: $text-strong-font-weight;
}
