.container {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.label {
  font-size: 18px;
  color: #666666;
  display: block;
  margin-right: 5px;
}

.text {
  font-size: 18px;
  display: block;

  span {
    display: block;
  }
}
