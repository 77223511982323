// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

$brand-font-family: Arial, sans-serif !default;
$brand-large-number-font-family: Arial, sans-serif !default;
$code-font-family: Consolas, Menlo, 'DejaVu Sans Mono', 'Liberation Mono', monospace !default;

$text-font-weight: 300 !default;
$text-strong-font-weight: 600 !default;
$control-font-weight: 600 !default;

$content-font-size: 16px !default;
$content-small-font-size: 14px !default;
$content-large-font-size: 24px !default;
$content-xlarge-font-size: 32px !default;
$control-font-size: 19px !default;
$menu-font-size: 19px !default;
$small-menu-font-size: 16px !default;
$large-menu-font-size: 24px !default;
$header-font-size: 24px !default;

$number-font-size: 24px !default;
$number-units-font-size: 20px !default;
$large-number-font-size: 36px !default;
$large-number-units-font-size: 24px !default;
$small-large-number-font-size: 20px !default;
$small-large-number-units-font-size: 16px !default;
$large-large-number-font-size: 64px !default;
$large-large-number-units-font-size: 48px !default;
$xlarge-large-number-font-size: 84px !default;
$xlarge-large-number-units-font-size: 60px !default;
$paragraph-font-size: $content-font-size !default;
$paragraph-line-height: 1.375 !default;
$paragraph-small-font-size: 14px !default;
$paragraph-small-line-height: 1.43 !default;
$paragraph-large-font-size: 24px !default;
$paragraph-large-line-height: 1.167 !default;
$paragraph-xlarge-font-size: 32px !default;
$paragraph-xlarge-line-height: 1.1875 !default;
$headline-font-size: 64px !default;
$headline-small-font-size: 30px !default;
$headline-large-font-size: 96px !default;
$headline-xlarge-font-size: 192px !default;
$headline-palm-font-size: 48px !default;
$headline-large-palm-font-size: 60px !default;
$headline-xlarge-palm-font-size: 72px !default;
$h1-font-size: 48px !default;
$h1-line-height: 1.125 !default;
$h1-palm-font-size: 36px !default;
$h2-font-size: 36px !default;
$h2-line-height: 1.23 !default;
$h2-palm-font-size: 30px !default;
$h3-font-size: 24px !default;
$h3-line-height: 1.333 !default;
$h4-font-size: 18px !default;
$h4-line-height: 1.333 !default;
$h5-font-size: 16px !default;
$h5-line-height: 1.375 !default;
$h6-font-size: 16px !default;
$h6-line-height: 1.375 !default;
$input-small-font-size: $content-font-size !default;
$input-medium-font-size: 24px !default;
$input-large-font-size: 54px !default;
$value-font-size: 36px !default;
$value-xsmall-font-size: 20px !default;
$value-small-font-size: 24px !default;
$value-large-font-size: 72px !default;
$value-xlarge-font-size: 192px !default;
