.heading {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.editIcons {
  display: flex;
  gap: 1rem;
  cursor: pointer;
}
