// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

@function tint($color, $percent) {
  @return mix(#ffffff, $color, $percent);
}

@function generate-tint($color) {
  @return tint($color, 5%);
}

#{$pending-background} {
  color: transparent;
}

#{$dark-background} {
  color: $active-colored-text-color;

  input::-webkit-input-placeholder {
    color: $active-colored-text-color;
  }

  input::-moz-placeholder {
    color: $active-colored-text-color;
  }

  input:-ms-input-placeholder {
    color: $active-colored-text-color;
  }
}

#{$light-background} {
  color: $text-color;

  input::-webkit-input-placeholder {
    color: $text-color;
  }

  input::-moz-placeholder {
    color: $text-color;
  }

  input:-ms-input-placeholder {
    color: $text-color;
  }
}

.#{$grommet-namespace}background-color-index-brand {
  background-color: $brand-color;

  &-a {
    background-color: rgba($brand-color, 0.94);
  }
}

.#{$grommet-namespace}border-color-index-brand {
  border-color: $brand-color;

  &-a {
    border-color: rgba($brand-color, 0.94);
  }
}

@for $i from 1 through length($brand-neutral-colors) {
  .#{$grommet-namespace}background-color-index-neutral-#{$i},
  .#{$grommet-namespace}background-color-index-neutral-#{$i + length($brand-neutral-colors)} {
    background-color: nth($brand-neutral-colors, $i);
  }

  .#{$grommet-namespace}background-color-index-neutral-#{$i}-t,
  .#{$grommet-namespace}background-color-index-neutral-#{$i + length($brand-neutral-colors)}-t {
    background-color: generate-tint(nth($brand-neutral-colors, $i));
  }

  .#{$grommet-namespace}background-color-index-neutral-#{$i}-a,
  .#{$grommet-namespace}background-color-index-neutral-#{$i + length($brand-neutral-colors)}-a {
    background-color: rgba(nth($brand-neutral-colors, $i), 0.8);
  }

  .#{$grommet-namespace}border-color-index-neutral-#{$i},
  .#{$grommet-namespace}border-color-index-neutral-#{$i + length($brand-neutral-colors)} {
    border-color: nth($brand-neutral-colors, $i);
  }

  .#{$grommet-namespace}border-color-index-neutral-#{$i}-t,
  .#{$grommet-namespace}border-color-index-neutral-#{$i + length($brand-neutral-colors)}-t {
    border-color: generate-tint(nth($brand-neutral-colors, $i));
  }

  .#{$grommet-namespace}color-index-neutral-#{$i},
  .#{$grommet-namespace}color-index-neutral-#{$i + length($brand-neutral-colors)} {
    color: nth($brand-neutral-colors, $i);
  }

  .#{$grommet-namespace}color-index-neutral-#{$i}-t,
  .#{$grommet-namespace}color-index-neutral-#{$i + length($brand-neutral-colors)}-t {
    color: generate-tint(nth($brand-neutral-colors, $i));
  }

  .#{$grommet-namespace}background-hover-color-index-neutral-#{$i}:hover,
  .#{$grommet-namespace}background-hover-color-index-neutral-#{$i + length($brand-neutral-colors)}:hover {
    background-color: rgba(nth($brand-neutral-colors, $i), 0.3);
  }

  .#{$grommet-namespace}border-small-hover-color-index-neutral-#{$i}:hover,
  .#{$grommet-namespace}border-small-hover-color-index-neutral-#{$i + length($brand-neutral-colors)}:hover {
    box-shadow: 0 0 0 1px nth($brand-neutral-colors, $i);
  }

  .#{$grommet-namespace}border-medium-hover-color-index-neutral-#{$i}:hover,
  .#{$grommet-namespace}border-medium-hover-color-index-neutral-#{$i + length($brand-neutral-colors)}:hover {
    box-shadow: 0 0 0 halve($inuit-base-spacing-unit) nth($brand-neutral-colors, $i);
  }

  .#{$grommet-namespace}border-large-hover-color-index-neutral-#{$i}:hover,
  .#{$grommet-namespace}border-large-hover-color-index-neutral-#{$i + length($brand-neutral-colors)}:hover {
    box-shadow: 0 0 0 $inuit-base-spacing-unit nth($brand-neutral-colors, $i);
  }
}

@for $i from 1 through length($brand-accent-colors) {
  .#{$grommet-namespace}background-color-index-accent-#{$i},
  .#{$grommet-namespace}background-color-index-accent-#{$i + length($brand-accent-colors)} {
    background-color: nth($brand-accent-colors, $i);
  }

  .#{$grommet-namespace}background-color-index-accent-#{$i}-t,
  .#{$grommet-namespace}background-color-index-accent-#{$i + length($brand-accent-colors)}-t {
    background-color: generate-tint(nth($brand-accent-colors, $i));
  }

  .#{$grommet-namespace}background-color-index-accent-#{$i}-a,
  .#{$grommet-namespace}background-color-index-accent-#{$i + length($brand-accent-colors)}-a {
    background-color: rgba(nth($brand-accent-colors, $i), 0.8);
  }

  .#{$grommet-namespace}border-color-index-accent-#{$i},
  .#{$grommet-namespace}border-color-index-accent-#{$i + length($brand-accent-colors)} {
    border-color: nth($brand-accent-colors, $i);
  }

  .#{$grommet-namespace}border-color-index-accent-#{$i}-t,
  .#{$grommet-namespace}border-color-index-accent-#{$i + length($brand-accent-colors)}-t {
    border-color: generate-tint(nth($brand-accent-colors, $i));
  }

  .#{$grommet-namespace}color-index-accent-#{$i},
  .#{$grommet-namespace}color-index-accent-#{$i + length($brand-accent-colors)} {
    color: nth($brand-accent-colors, $i);
  }

  .#{$grommet-namespace}color-index-accent-#{$i}-t,
  .#{$grommet-namespace}color-index-accent-#{$i + length($brand-accent-colors)}-t {
    color: generate-tint(nth($brand-accent-colors, $i));
  }

  .#{$grommet-namespace}background-hover-color-index-accent-#{$i}:hover,
  .#{$grommet-namespace}background-hover-color-index-accent-#{$i + length($brand-accent-colors)}:hover {
    background-color: rgba(nth($brand-accent-colors, $i), 0.3);
  }

  .#{$grommet-namespace}border-small-hover-color-index-accent-#{$i}:hover,
  .#{$grommet-namespace}border-small-hover-color-index-accent-#{$i + length($brand-accent-colors)}:hover {
    box-shadow: 0 0 0 1px nth($brand-accent-colors, $i);
  }

  .#{$grommet-namespace}border-medium-hover-color-index-accent-#{$i}:hover,
  .#{$grommet-namespace}border-medium-hover-color-index-accent-#{$i + length($brand-accent-colors)}:hover {
    box-shadow: 0 0 0 halve($inuit-base-spacing-unit) nth($brand-accent-colors, $i);
  }

  .#{$grommet-namespace}border-large-hover-color-index-accent-#{$i}:hover,
  .#{$grommet-namespace}border-large-hover-color-index-accent-#{$i + length($brand-accent-colors)}:hover {
    box-shadow: 0 0 0 $inuit-base-spacing-unit nth($brand-accent-colors, $i);
  }
}

@for $i from 1 through length($brand-grey-colors) {
  .#{$grommet-namespace}background-color-index-grey-#{$i},
  .#{$grommet-namespace}background-color-index-grey-#{$i + length($brand-grey-colors)} {
    background-color: nth($brand-grey-colors, $i);
  }

  .#{$grommet-namespace}background-color-index-grey-#{$i}-a,
  .#{$grommet-namespace}background-color-index-grey-#{$i + length($brand-grey-colors)}-a {
    background-color: rgba(nth($brand-grey-colors, $i), 0.8);
  }

  .#{$grommet-namespace}border-color-index-grey-#{$i},
  .#{$grommet-namespace}border-color-index-grey-#{$i + length($brand-grey-colors)} {
    border-color: nth($brand-grey-colors, $i);
  }

  .#{$grommet-namespace}background-hover-color-index-grey-#{$i}:hover,
  .#{$grommet-namespace}background-hover-color-index-grey-#{$i + length($brand-grey-colors)}:hover {
    background-color: rgba(nth($brand-grey-colors, $i), 0.3);
  }

  .#{$grommet-namespace}border-small-hover-color-index-grey-#{$i}:hover,
  .#{$grommet-namespace}border-small-hover-color-index-grey-#{$i + length($brand-grey-colors)}:hover {
    box-shadow: 0 0 0 1px nth($brand-grey-colors, $i);
  }

  .#{$grommet-namespace}border-medium-hover-color-index-grey-#{$i}:hover,
  .#{$grommet-namespace}border-medium-hover-color-index-grey-#{$i + length($brand-grey-colors)}:hover {
    box-shadow: 0 0 0 halve($inuit-base-spacing-unit) nth($brand-grey-colors, $i);
  }

  .#{$grommet-namespace}border-large-hover-color-index-grey-#{$i}:hover,
  .#{$grommet-namespace}border-large-hover-color-index-grey-#{$i + length($brand-grey-colors)}:hover {
    box-shadow: 0 0 0 $inuit-base-spacing-unit nth($brand-grey-colors, $i);
  }
}

@for $i from 1 through length($brand-graph-colors) {
  .#{$grommet-namespace}background-color-index-graph-#{$i},
  .#{$grommet-namespace}background-color-index-graph-#{$i + length($brand-graph-colors)} {
    background-color: nth($brand-graph-colors, $i);
  }

  .#{$grommet-namespace}border-color-index-graph-#{$i},
  .#{$grommet-namespace}border-color-index-graph-#{$i + length($brand-graph-colors)} {
    border-color: nth($brand-graph-colors, $i);
  }
}

@each $status, $color in $brand-status-colors {
  .#{$grommet-namespace}background-color-index-#{$status} {
    background-color: $color;
  }

  .#{$grommet-namespace}border-color-index-#{$status} {
    border-color: $color;
  }

  .#{$grommet-namespace}color-index-#{$status} {
    color: $color;
  }

  .#{$grommet-namespace}background-hover-color-index-#{$status}:hover {
    background-color: rgba($color, 0.3);
  }

  .#{$grommet-namespace}border-small-hover-color-index-#{$status}:hover {
    box-shadow: 0 0 0 1px $color;
  }

  .#{$grommet-namespace}border-medium-hover-color-index-#{$status}:hover {
    box-shadow: 0 0 0 halve($inuit-base-spacing-unit) $color;
  }

  .#{$grommet-namespace}border-large-hover-color-index-#{$status}:hover {
    box-shadow: 0 0 0 $inuit-base-spacing-unit $color;
  }
}

@for $i from 1 through length($brand-light-colors) {
  .#{$grommet-namespace}background-color-index-light-#{$i},
  .#{$grommet-namespace}background-color-index-light-#{$i + length($brand-light-colors)} {
    background-color: nth($brand-light-colors, $i);
  }

  .#{$grommet-namespace}background-color-index-light-#{$i}-a,
  .#{$grommet-namespace}background-color-index-light-#{$i + length($brand-light-colors)}-a {
    background-color: rgba(nth($brand-light-colors, $i), 0.8);
  }

  .#{$grommet-namespace}border-color-index-light-#{$i},
  .#{$grommet-namespace}border-color-index-light-#{$i + length($brand-light-colors)} {
    border-color: nth($brand-light-colors, $i);
  }

  .#{$grommet-namespace}background-hover-color-index-light-#{$i}:hover,
  .#{$grommet-namespace}background-hover-color-index-light-#{$i + length($brand-light-colors)}:hover {
    background-color: rgba(nth($brand-light-colors, $i), 0.3);
  }

  .#{$grommet-namespace}border-small-hover-color-index-light-#{$i}:hover,
  .#{$grommet-namespace}border-small-hover-color-index-light-#{$i + length($brand-light-colors)}:hover {
    box-shadow: 0 0 0 1px nth($brand-light-colors, $i);
  }

  .#{$grommet-namespace}border-medium-hover-color-index-light-#{$i}:hover,
  .#{$grommet-namespace}border-medium-hover-color-index-light-#{$i + length($brand-light-colors)}:hover {
    box-shadow: 0 0 0 halve($inuit-base-spacing-unit) nth($brand-light-colors, $i);
  }

  .#{$grommet-namespace}border-large-hover-color-index-light-#{$i}:hover,
  .#{$grommet-namespace}border-large-hover-color-index-light-#{$i + length($brand-light-colors)}:hover {
    box-shadow: 0 0 0 $inuit-base-spacing-unit nth($brand-light-colors, $i);
  }
}

.#{$grommet-namespace}background-color-index-unset {
  background-color: $unset-color;
}
