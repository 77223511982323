.bottomSpace {
  margin-bottom: 20px;
}

.error {
  font-size: 12px;
  color: #ca0e0b;
}

.inline {
  display: flex;
  align-items: center;
}

.select {
  width: 100%;
}

.icon {
  margin-left: 1%;
}
