.singlePlan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
}

.description {
  width: 100%;
  margin-bottom: 16px;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.adminControls {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.price {
  margin-bottom: 16px;
  font-weight: 700;
}

.buyButton {
  max-width: 100% !important;
}

.singlePlanLabel {
  font-weight: 700;
}

.singlePlanDescription {
  font-style: italic;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .singlePlan {
    flex-direction: row;
    justify-content: space-between;
  }

  .controlsAdmin {
    align-items: flex-end;
  }

  .description {
    width: 100%;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1280px) {
  .controls {
    margin-left: 80px;
  }
}
