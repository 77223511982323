.categoriesContainer {
  border-radius: 10px;
  border: 2px solid grey;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  margin-bottom: 30px;
}

.done {
  display: flex;
  justify-content: center;
}
