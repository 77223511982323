.modal {
  padding: 0 !important;
}

.content {
  padding: 3rem;
}

.container {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;
}

.buttonBar {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 1rem;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 1rem;
  border-top: rgb(145, 145, 145) solid 1px;
}

@media (max-width: 1000px) {
  .buttonBar {
    justify-content: center;
  }
}
