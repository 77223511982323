@import '../../../brandConfig/colors/colors.scss';

.button {
  display: block !important;
  margin: 20px 0px 20px auto;
  width: 100%;
  text-align: center;
  padding: 10px 25px;
  font-weight: 700 !important;
  background: $brand-color !important;
  color: white !important;
  text-decoration: none !important;
}

.disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .buttonDebtors {
    float: left;
    margin-top: 10px;
  }

  .button {
    width: 100%;
  }
}
