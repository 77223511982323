// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

// ARC

$meter-arc-size-xsmall: ($size-xsmall * 0.75);
$meter-arc-size-small: ($size-small * 0.75);
$meter-arc-size-medium: ($size-medium * 0.75);
$meter-arc-size-large: ($size-large * 0.75);
$meter-arc-size-xlarge: ($size-xlarge * 0.75);
$meter-arc-size-default: $meter-arc-size-small;

.#{$grommet-namespace}meter--arc:not(.#{$grommet-namespace}meter--vertical) {
  .#{$grommet-namespace}meter__graphic {
    width: $meter-size-default;
    min-width: $size-xsmall;
    height: auto;
  }

  &.#{$grommet-namespace}meter--xsmall {
    .#{$grommet-namespace}meter__graphic {
      width: $size-xsmall;
      height: $meter-arc-size-xsmall;
    }
  }

  &.#{$grommet-namespace}meter--small {
    .#{$grommet-namespace}meter__graphic {
      width: $size-small;
      height: $meter-arc-size-small;
    }
  }

  &.#{$grommet-namespace}meter--medium {
    .#{$grommet-namespace}meter__graphic {
      @include media-query(palm) {
        width: $size-small;
        height: $meter-arc-size-small;
      }

      @include media-query(lap-and-up) {
        width: $size-medium;
        height: $meter-arc-size-medium;
      }
    }
  }

  &.#{$grommet-namespace}meter--large {
    .#{$grommet-namespace}meter__graphic {
      @include media-query(palm) {
        width: $size-small;
        height: $meter-arc-size-small;
      }

      @include media-query(lap-and-up) {
        width: $size-large;
        height: $meter-arc-size-large;
      }
    }
  }

  &.#{$grommet-namespace}meter--xlarge {
    .#{$grommet-namespace}meter__graphic {
      @include media-query(palm) {
        width: $size-small;
        height: $meter-arc-size-small;
      }

      @include media-query(lap-and-up) {
        width: $size-xlarge;
        height: $meter-arc-size-xlarge;
      }
    }
  }
}

.#{$grommet-namespace}meter--arc.#{$grommet-namespace}meter--vertical {
  .#{$grommet-namespace}meter__graphic {
    display: inline;
    width: $meter-arc-size-default;
    height: $meter-size-default;
  }

  &.#{$grommet-namespace}meter--xsmall {
    .#{$grommet-namespace}meter__graphic {
      width: $meter-arc-size-xsmall;
      height: $size-xsmall;
    }
  }

  &.#{$grommet-namespace}meter--small {
    .#{$grommet-namespace}meter__graphic {
      width: $meter-arc-size-small;
      height: $size-small;
    }
  }

  &.#{$grommet-namespace}meter--medium {
    .#{$grommet-namespace}meter__graphic {
      @include media-query(palm) {
        width: $meter-arc-size-small;
        height: $size-small;
      }

      @include media-query(lap-and-up) {
        width: $meter-arc-size-medium;
        height: $size-medium;
      }
    }
  }

  &.#{$grommet-namespace}meter--large {
    .#{$grommet-namespace}meter__graphic {
      @include media-query(palm) {
        width: $meter-arc-size-small;
        height: $size-small;
      }

      @include media-query(lap-and-up) {
        width: $meter-arc-size-large;
        height: $size-large;
      }
    }
  }

  &.#{$grommet-namespace}meter--xlarge {
    .#{$grommet-namespace}meter__graphic {
      @include media-query(palm) {
        width: $meter-arc-size-small;
        height: $size-small;
      }

      @include media-query(lap-and-up) {
        width: $meter-arc-size-xlarge;
        height: $size-xlarge;
      }
    }
  }
}
