.root {
  min-width: 250px;
}

.modalBtnContainer {
  float: right;
}

.classDetail {
  margin-bottom: 5px;

  .classDetailLabel {
    font-weight: bold;
  }
}

@media screen and (max-width: 350px) {
  .root {
    min-width: auto;
  }
}
