.listHeader {
  font-size: 19px !important;
  margin: 0 0 13px 0;
  text-transform: uppercase;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: lightgray;
  padding: 10px 20px;

  button {
    margin: auto 0 0 auto;
    height: 73px;
  }
}

@media screen and (max-width: 640px) {
  .header {
    display: block;

    button {
      margin-top: 10px;
      height: auto;
      width: 100%;
      max-width: 100%;
    }
  }
}
