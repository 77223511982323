.info {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 3px;
  margin-bottom: 10px;
  background-color: #fff5d0;
  padding: 10px;
  font-size: 15px;
}

.promoCodeDiv {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  input {
    width: 100%;
    max-width: 305px;
  }
}

.promoCode {
  margin-left: 10px;
}

.apply {
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
