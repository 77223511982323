.root {
  text-align: center;

  h5 {
    margin: 20px 0px 0px 0px;
  }
}

.selector {
  width: 293px !important;
  margin: auto;
}

.button {
  margin-top: 40px;
  width: 294px !important;
}
