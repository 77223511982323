.showHideBtn {
  background: #dddddd;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  &:hover {
    cursor: pointer;
    filter: brightness(55%);
  }
}

.shiftsHeader {
  font-weight: bold;
  padding-top: 5px;
}

.shifts {
  justify-content: center;
  padding: 0 20px;
  background: #f5f5f5;
  display: grid;
  grid-template-columns: 125px 100px 100px;
}
