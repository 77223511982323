@import '../../../../brandConfig/colors/colors.scss';

.dragContainer {
  padding: 2rem;
}

.draggable {
  border: 1px solid $brand-color;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  cursor: move;
}
