.btnFirst {
  padding: 0 9px !important;
  margin-top: 32px;
}

.btn {
  padding: 0 9px !important;
}

.label {
  font-size: 1.1875rem;
  color: #333;
  font-weight: 300;
  margin-bottom: 9px;
}

.xsLabel {
  font-weight: bold;
}

.root {
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 180px 1fr 1fr 79px;
  grid-gap: 15px;

  :global {
    .grommetux-date-time,
    .grommetux-date-time input {
      width: 100% !important;
    }

    .grommetux-button--plain .grommetux-button__icon {
      padding: 10px 0;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

select {
  padding: 11px 23px;
}

@media screen and (max-width: 700px) {
  .root {
    display: block;
    padding: 15px;
    background: #f5f5f5;
    border-radius: 10px;

    input {
      background: white;
    }
  }

  .buttonContainer button {
    width: 45%;
    text-align: center;
    margin-top: 10px;
  }
}
