// (C) Copyright 2016 Hewlett Packard Enterprise Development LP

$sun-burst-slice-width: round($inuit-base-spacing-unit);
$sun-burst-small-slice-width: round($inuit-base-spacing-unit / 2);
$sun-burst-large-slice-width: round($inuit-base-spacing-unit * 1.5);
$sun-burst-xlarge-slice-width: round($inuit-base-spacing-unit * 3);

@include keyframes(fade-in) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(draw-arc) {
  0% {
    stroke-dashoffset: -$size-medium;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.#{$grommet-namespace}sun-burst {
  position: relative;
  height: $size-medium;
  width: $size-medium;
  max-width: 100%;
}

.#{$grommet-namespace}sun-burst__graphic {
  @include animation('fade-in 2.5s');
  outline: none;
}

.#{$grommet-namespace}sun-burst__graphic--focus {
  border-color: $focus-border-color;
  box-shadow: 0 0 1px 1px $focus-border-color;
}

.#{$grommet-namespace}sun-burst__label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.#{$grommet-namespace}sun-burst__slice {
  stroke-linecap: butt;
  stroke-dasharray: quadruple($size-medium) quadruple($size-medium);
  stroke-dashoffset: 0;
  stroke: $meter-background-color;
  @include graph-stroke-color();
  @include animation('draw-arc 1.5s linear');
  transition: opacity $base-animation-duration;
}

.#{$grommet-namespace}sun-burst__slice--hot {
  cursor: pointer;
  outline: none;
}

.#{$grommet-namespace}sun-burst--active {
  .#{$grommet-namespace}sun-burst__slice {
    opacity: 0.7;
  }

  .#{$grommet-namespace}sun-burst__slice--active {
    opacity: 1;
  }
}

.#{$grommet-namespace}sun-burst--small {
  height: $size-small;
  width: $size-small;
}

.#{$grommet-namespace}sun-burst--large {
  height: $size-large;
  width: $size-large;
}

.#{$grommet-namespace}sun-burst--xlarge {
  height: $size-xlarge;
  width: $size-xlarge;
}

.#{$grommet-namespace}sun-burst--full {
  width: 100%;
}
