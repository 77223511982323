// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}accordion-panel {
  margin: halve(halve(halve($inuit-base-spacing-unit)));
}

.#{$grommet-namespace}accordion-panel__header {
  color: $secondary-text-color;

  #{$dark-background-context} {
    color: $colored-text-color;
  }

  #{$light-background-context} {
    color: $secondary-text-color;
  }
}

.#{$grommet-namespace}accordion-panel__header:hover {
  color: $hover-text-color;

  #{$dark-background-context} {
    color: $colored-text-color;
  }

  #{$light-background-context} {
    color: $hover-text-color;
  }
}

.#{$grommet-namespace}accordion-panel--active {
  .#{$grommet-namespace}accordion-panel__control {
    transform: rotate(90deg);
  }
}
