.filters {
  border: solid 1px rgba(0, 0, 0, 0.15) !important;
  padding: 30px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 10%;
  margin-bottom: 20px;

  h4 {
    margin-bottom: 0.3em;
  }
}

.classTypes label {
  display: block;
  margin: 5px 0;
}

.grid {
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr);
  grid-gap: 20px;
}

.item {
  margin-left: 5px;
  padding: 15px 0;
}

.header {
  display: block !important;
}

.label {
  display: none;
}

@media screen and (max-width: 800px) {
  .header {
    display: none !important;
  }
  .label {
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
  }
  .grid {
    display: block;
  }
  .dataContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .item {
    background: #dddddd;
    padding: 10px 15px;
    border-radius: 10px;
  }

  .name {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 630px) {
  .filters {
    display: block;
  }
  .dataContainer {
    grid-template-columns: 1fr;
  }
}
