.header {
  border-bottom: 2px solid #d7d7d7;
  padding: 11px 0;
  text-align: center;
  margin: 0;
}

.singleDay {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .singleDay {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 1280px) {
}
