.tableListHeader {
  margin: 0 !important;
}

.filter {
  display: flex;
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 10px;
  width: 100%;
  background: lightgrey;

  h4 {
    margin: auto 20px auto 0px;
  }

  input {
    width: 80%;
    background: white;
  }
}

.header {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  button {
    margin: auto 0px !important;
    height: 48px;
  }
}

@media screen and (max-width: 678px) {
  .root h3,
  h4,
  div {
    font-size: 1rem;
  }
  .filter h4 {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 400px) {
  .filter {
    display: block;
    margin-top: 10px;

    input {
      width: 100%;
    }
  }

  .header {
    display: block;

    button {
      width: 100%;
    }
  }
}
