.headerMenu {
  margin-top: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.mobileMenuContainer {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.userMenuIcon {
  width: 30px;
  height: 30px;
}

.headerIcons {
  display: flex;
  align-items: center;
}

.dashboardIcon {
  margin-left: 1rem;
  @media screen and (max-width: 870px) {
    display: none;
  }
}

@media screen and (max-width: 870px) {
  .hiddenMobile {
    display: none;
  }
  .mobileMenuContainer {
    width: auto;
  }
  .mobileMenuBox {
    align-items: flex-end !important;
  }
  .navbarContainer {
    display: flex;
  }
}

@media screen and (min-width: 870px) {
  .mobileMenuContainer {
    display: none;
  }

  .userMenuIcon {
    width: 36px;
    height: 36px;
  }
}

@media (min-width: 1280px) {
  .header {
    height: 96px;
  }
}

@media screen and (max-width: 870px) {
  .userMenu {
    display: none !important;
  }
}
