.root {
  width: 100%;

  button {
    width: 100%;
  }
}

.btn {
  width: 100%;
  margin: 10px auto;
  max-width: 100%;
}

.spinningIcon {
  display: block;
  margin: 25px auto;
}
