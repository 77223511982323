// (C) Copyright 2014-2018 Hewlett Packard Enterprise Development LP

@mixin range-track {
  width: 100%;
  height: $control-border-width;
  background-color: $control-background-color;
}

@mixin range-thumb {
  position: relative;
  height: $control-size;
  width: $control-size;
  overflow: visible;
}

.grommet a:not(.#{$grommet-namespace}anchor):not(.#{$grommet-namespace}button) {
  @include anchor-style;
}

a:not(.#{$grommet-namespace}anchor):not(.#{$grommet-namespace}button) {
  @include background-context-color($link-color, $link-hover-color);
}

.grommet {
  input[type='button'],
  input[type='submit'] {
    text-align: center;
    line-height: inherit;
  }

  a.#{$grommet-namespace}button {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  $small-drop-caret: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAOhJREFUSA3tksENgzAMRUmrrlApuTAAxxw6QvfojYmYKtw6QpUDI1Rq6o8MStsAMT1UlbAUcMB+33FcFJttHfifDlhrT7QO31YMBlgDZw8HH5RSF3JLY0zrvX8MAZI3F1gT66y17ohz2zGgDSFc6UdF+5oDJWwUidMDXoFFfgtAfwJUjMppX7KI6CQJeOOcu48CcNaKzMFfBNaILME/BCQiOfCkQI5ILhwshceUpUAcG0/LeKEpzqwAEhIiRTSKs3Dk92MKZ8rep4vgR57zRTiYiwIIikVo29HKgiNXZGgXt0yUtwX/tgNPQqatJ1aBLFMAAAAASUVORK5CYII=);

  select {
    border-color: $border-color;
    padding-right: double($inuit-base-spacing-unit);
    -webkit-appearance: none;
    -moz-appearance: none;
    background: $small-drop-caret no-repeat center right halve($inuit-base-spacing-unit);
    cursor: pointer;

    &::-moz-focus-inner {
      border: none;
    }

    &.plain {
      border: none;
    }

    &:focus {
      padding-right: double($inuit-base-spacing-unit);
    }
  }

  input[type='range'] {
    position: relative;
    -webkit-appearance: none;
    border-color: transparent;
    height: $control-size;
    padding: 0px;
    cursor: pointer;
    overflow-x: hidden;

    &:focus {
      outline: none;
    }

    &::-moz-focus-inner {
      border: none;
    }

    &::-moz-focus-outer {
      border: none;
    }

    &::-webkit-slider-runnable-track {
      @include range-track();
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      @include range-thumb();
      margin-top: -(round($control-size * 0.45));
      border: $control-border-width solid $brand-color;
      border-radius: $control-size;
      background-color: $background-color;
      cursor: pointer;

      &:hover {
        border-color: $active-icon-color;
      }
    }

    &::-moz-range-track {
      @include range-track();
    }

    &::-moz-range-thumb {
      @include range-thumb();
      // since the :before doesn't work on the thumb, restyle a bit
      border: $control-border-width solid $brand-color;
      height: $control-size - ($control-border-width * 2);
      width: $control-size - ($control-border-width * 2);
      border-radius: $control-size;
      background-color: $background-color;
    }

    &:hover::-moz-range-thumb {
      border-color: $active-icon-color;
    }

    // This isn't working
    //&::-moz-range-thumb:before {
    //	content: "";
    //	position: absolute;
    //	left: -3000px;
    //	right: halve($inuit-base-spacing-unit);
    //	top: quarter($inuit-base-spacing-unit);
    //	bottom: quarter($inuit-base-spacing-unit);
    //	background: $brand-color;
    //}

    &::-ms-track {
      @include range-track();
      //background: transparent;
      border-color: transparent;
      //border-width: 16px 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: $brand-color;
      border-color: transparent;
    }

    &::-ms-fill-upper {
      background: $control-background-color;
      border-color: transparent;
    }

    &::-ms-thumb {
      @include range-thumb();
      position: relative;
      top: quarter($inuit-base-spacing-unit);
      border: $control-border-width solid $icon-color;
      height: $control-size - ($control-border-width * 2);
      width: $control-size - ($control-border-width * 2);
      border-radius: $control-size;
      background-color: $background-color;
    }

    &:hover::-ms-thumb {
      border-color: $active-icon-color;
    }
  }

  #{$dark-background} {
    input[type='range'] {
      &::-webkit-slider-runnable-track {
        background-color: $colored-meter-background-color;
      }

      &::-webkit-slider-thumb {
        background-color: $active-colored-icon-color;
        border: $control-border-width solid $active-colored-icon-color;
      }

      &::-moz-range-thumb {
        background-color: $active-colored-icon-color;
        border: $control-border-width solid $active-colored-icon-color;
      }

      &::-ms-fill-lower {
        background: #fff;
      }

      &::-ms-fill-upper {
        background: $colored-meter-background-color;
      }

      &::-ms-thumb {
        border-color: $active-colored-icon-color;
      }

      &:hover::-ms-thumb {
        border-color: $active-colored-icon-color;
        background-color: $active-colored-icon-color;
      }
    }
  }

  #{$light-background} {
    input[type='range'] {
      &::-webkit-slider-runnable-track {
        background-color: $control-background-color;
      }

      &::-webkit-slider-thumb {
        background-color: $active-icon-color;
        border: $control-border-width solid $active-icon-color;
      }

      &::-moz-range-thumb {
        background-color: $active-icon-color;
        border: $control-border-width solid $active-icon-color;
      }

      &::-ms-fill-lower {
        background: $brand-color;
      }

      &::-ms-fill-upper {
        background: $control-background-color;
      }

      &::-ms-thumb {
        border-color: $icon-color;
      }

      &:hover::-ms-thumb {
        border-color: $active-icon-color;
        background-color: $background-color;
      }
    }
  }
}
