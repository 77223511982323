.profilePicture {
  cursor: pointer;
  align-self: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 20px;
}

.visibility {
  visibility: hidden;
}

@media (min-width: 768px) {
  .imageUploadContainer {
    margin-left: auto;
    margin-right: auto;
  }
}
