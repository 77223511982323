// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

// Unbranded Grommet purple
// http://paletton.com/#uid=14m0u0kon++cB+MiX+Yub+WLPZB

$brand-color: #8c50ff !default;
$brand-neutral-colors: (#5d0cfb, #7026ff, #767676) !default;
$brand-accent-colors: (#c3a4fe, #a577ff) !default;
$brand-link-color: #8c50ff !default;
$brand-status-colors: (
  critical: #ff856b,
  error: #ff856b,
  warning: #ffb86b,
  ok: #4eb976,
  unknown: #a8a8a8,
  disabled: #a8a8a8
) !default;
$brand-error-colored-color: #f59f9f !default;
$brand-grey-colors: (#000001, #333333, #444444, #555555, #666666) !default;
$brand-graph-colors: $brand-neutral-colors !default;
$brand-light-colors: (#ffffff, #f5f5f5) !default;

$brand-color-lighter: lighten($brand-color, 23%) !default;
$brand-color-darker: darken($brand-color, 9%) !default;

$text-color: #333 !default;
$secondary-text-color: #666 !default; // meets AA accessibility
$hover-text-color: #000 !default;
$placeholder-text-color: #aaa !default;
$colored-text-color: rgba(255, 255, 255, 0.85) !default; //#dbdbdb;
$active-colored-text-color: #fff !default;
$inactive-colored-text-color: rgba(255, 255, 255, 0.7) !default;
$link-color: $brand-link-color !default;
$link-hover-color: $brand-color-darker !default;
// 63 was empirically determined based on HPE critical and warning colors
$colored-text-color-lightness-threshold: 63 !default;

$background-color: #fff !default;
$secondary-background-color: #f5f5f5 !default;
$hover-background-color: rgba(#ddd, 0.5) !default;
$colored-active-background-color: rgba(0, 0, 0, 0.15) !default;
$colored-hover-background-color: rgba(0, 0, 0, 0.1) !default;
$selected-background-color: $brand-color-lighter !default;
$selected-text-color: $text-color !default;
$selected-primary-background-color: $brand-color !default;
$selected-primary-text-color: $colored-text-color !default;

$border-color: rgba(0, 0, 0, 0.15) !default;
$strong-border-color: #000 !default;
$colored-border-color: rgba(255, 255, 255, 0.5) !default;
$colored-strong-border-color: #fff !default;

$heading-text-color: $text-color !default;
$paragraph-text-color: $secondary-text-color !default;

// Control Icons

$icon-color: #666 !default; // meets AA accessibility
$active-icon-color: #000 !default;
$disabled-icon-color: #ccc !default;
$colored-icon-color: rgba(255, 255, 255, 0.7) !default; //#dbdbdb;
$colored-status-color: rgba(255, 255, 255, 0.9) !default; //#dbdbdb;
$active-colored-icon-color: #fff !default;
$icon-badge-background-color: nth($brand-accent-colors, 1) !default;
$icon-badge-text-color: $text-color !default;

$unset-color: #ddd !default;
$control-background-color: rgba($text-color, 0.2) !default;

$meter-color: $brand-color !default;
$meter-background-color: rgba(0, 0, 0, 0.1) !default;
$colored-meter-background-color: rgba(#fff, 0.2) !default;
$threshold-color: rgba($text-color, 0.2) !default;
$cursor-color: $text-color !default;
$toast-background-color: rgba(#fff, 0.9) !default;

// Controls

$focus-border-color: nth($brand-accent-colors, 1) !default;
$control-brand-color: $brand-color !default;

$button-border-color: $brand-color !default;
$button-text-color: $text-color !default;
$button-secondary-color: rgba(#333, 0.6) !default;
$button-accent-color: nth($brand-accent-colors, 1) !default;
$button-critical-color: map-get($brand-status-colors, critical) !default;
$button-drop-shadow-color: rgba(170, 170, 170, 0.5) !default;
$button-colored-border-color: rgba(255, 255, 255, 0.7) !default; ///rgba(255, 255, 255, 0.7) !default;

$layer-overlay-background-color: rgba(0, 0, 0, 0.5) !default;
$layer-background-color: #fff !default; // rgba($background-color, 0.95); ///inherit; //rgba(255, 255, 255, 0.95);
$layer-border: none !default; // 1px solid $brand-color;
$layer-box-shadow: none !default; // 0px 2px 4px rgba(0, 0, 0, 0.3) !default;
$drop-background: rgba(#f8f8f8, 0.95) !default;
$drop-border: none !default; // 1px solid $border-color !default;
$drop-box-shadow: none !default; // 0px 2px 4px rgba(0, 0, 0, 0.3) !default;
$form-field-background-color: #fff !default;
$drop-background-color: rgba(255, 255, 255, 0.9) !default;
$header-background-color: rgba(255, 255, 255, 0.9) !default;
$footer-background-color: rgba(255, 255, 255, 0.9) !default;
$active-background-color: rgba(255, 255, 255, 0.8) !default;
$sort-placeholder-background-color: rgba(0, 0, 255, 0.1) !default;

$inverse-background-color: nth($brand-neutral-colors, 1) !default;
$inverse-color: #fff !default;

$pending-background: '.#{$grommet-namespace}background-color-index--pending' !default;
$dark-background: '.#{$grommet-namespace}background-color-index--dark, .#{$grommet-namespace}background-color-index--light .#{$grommet-namespace}background-color-index--dark' !default;
$light-background: '.#{$grommet-namespace}background-color-index--light, .#{$grommet-namespace}background-color-index--dark .#{$grommet-namespace}background-color-index--light' !default;

$pending-background-context: '.#{$grommet-namespace}background-color-index--pending &' !default;
$dark-background-context: '.#{$grommet-namespace}background-color-index--dark &, .#{$grommet-namespace}background-color-index--light .#{$grommet-namespace}background-color-index--dark &' !default;
$light-background-context: '.#{$grommet-namespace}background-color-index--light &, .#{$grommet-namespace}background-color-index--dark .#{$grommet-namespace}background-color-index--light &' !default;

$pending-background-and: '&.#{$grommet-namespace}background-color-index--pending' !default;
$dark-background-and: '&.#{$grommet-namespace}background-color-index--dark, .#{$grommet-namespace}background-color-index--light &.#{$grommet-namespace}background-color-index--dark' !default;
$light-background-and: '&.#{$grommet-namespace}background-color-index--light, .#{$grommet-namespace}background-color-index--dark &.#{$grommet-namespace}background-color-index--light' !default;
