.titleBar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem 0;
}

.dateLength {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.metaData {
  display: flex;
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
  gap: 2rem;
}
