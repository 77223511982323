// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

.#{$grommet-namespace}select {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.#{$grommet-namespace}select__input {
  @include input();
  width: 100%;
  padding-right: round($inuit-base-spacing-unit * 2.5);
  cursor: inherit;
  color: inherit;
  pointer-events: none;

  &[readonly] {
    color: $text-color;

    #{$dark-background-context} {
      color: $colored-text-color;
    }

    #{$light-background-context} {
      color: $text-color;
    }
  }
}

.#{$grommet-namespace}select__input:focus {
  padding-right: round($inuit-base-spacing-unit * 2.5 - ($input-border-width + 1));
}

// Disable IE's clear icon
.#{$grommet-namespace}select__input::-ms-clear {
  display: none;
}

.#{$grommet-namespace}select__value {
  // leave room for control
  padding: quarter($inuit-base-spacing-unit) double($inuit-base-spacing-unit) quarter($inuit-base-spacing-unit) 0;
  border: $input-border-width solid $border-color;
  border-radius: $border-radius;
}

.#{$grommet-namespace}select__control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: quarter($inuit-base-spacing-unit);

  // Hide text behind select__control
  svg {
    background-color: $background-color;
  }
}

.#{$grommet-namespace}select__search {
  width: 100%;
}

.#{$grommet-namespace}select__options {
  margin: 0px;
  list-style-type: none;
}

.#{$grommet-namespace}select__option {
  // min-height handles blank options
  min-height: $inuit-base-spacing-unit + halve($inuit-base-spacing-unit);
  padding: quarter($inuit-base-spacing-unit) 0;
  cursor: pointer;
}

.#{$grommet-namespace}select__drop {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  .#{$grommet-namespace}select__option {
    @include text();
    color: $text-color;
    padding: quarter($inuit-base-spacing-unit) $inuit-base-spacing-unit;

    &.#{$grommet-namespace}select__option--element {
      padding: quarter($inuit-base-spacing-unit) 0;
    }

    &:hover,
    &--active {
      background-color: $hover-background-color;
    }

    &--selected {
      color: $hover-text-color;
      background-color: $hover-background-color;
    }
  }
}
