.tilesContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 280px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .tilesContainer {
    max-width: 376px;
  }
}

@media (min-width: 1024px) {
  .tilesContainer {
    max-width: 100%;
    justify-content: left;
  }
}
