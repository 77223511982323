// (C) Copyright 2014-2016 Hewlett Packard Enterprise Development LP

@mixin text-align {
  &--align-start {
    text-align: left;

    html.rtl & {
      text-align: right;
    }
  }

  &--align-center {
    text-align: center;
  }

  &--align-end {
    text-align: right;

    html.rtl & {
      text-align: left;
    }
  }
}
