$card-hover-color: #ebebeb;

@mixin truncate-paragraph($truncate-p-line-height, $truncate-p-font-size) {
  max-height: ($truncate-p-line-height * $truncate-p-font-size * 8);
  // p line-height * (# lines)

  &::after {
    margin-top: ($truncate-p-line-height * $truncate-p-font-size * 7);
    // p line-height * (# lines - 1)
  }

  @include media-query(palm) {
    max-height: ($truncate-p-line-height * $truncate-p-font-size * 5);

    &::after {
      margin-top: ($truncate-p-line-height * $truncate-p-font-size * 4);
    }
  }
}

.#{$grommet-namespace}card {
  @include media-query(palm) {
    padding: 0;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &.#{$grommet-namespace}box--responsive {
    @include media-query(palm) {
      width: auto;
      margin: 0;
    }
  }

  div:focus, // too generic, change
  a:focus {
    outline: none;
  }
}

.#{$grommet-namespace}card__thumbnail {
  min-height: $size-small;
}

.#{$grommet-namespace}card__content--truncate {
  // truncate text paragraphs after 8 lines
  .#{$grommet-namespace}paragraph {
    max-width: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    position: relative;

    @include truncate-paragraph($paragraph-line-height, $paragraph-font-size);

    // fallback for -webkit-line-clamp
    &::after {
      content: '...';
      text-align: right;
      top: 0;
      right: 0;
      display: block;
      position: absolute;
      background: linear-gradient(to right, transparent, $background-color 50%);
      width: $inuit-base-spacing-unit;
    }

    @supports (-webkit-line-clamp: 1) {
      &::after {
        display: none;
      }
    }

    @include media-query(palm) {
      -webkit-line-clamp: 4;
    }
  }

  .#{$grommet-namespace}paragraph--small {
    @include truncate-paragraph($paragraph-small-line-height, $paragraph-small-font-size);
  }

  .#{$grommet-namespace}paragraph--large {
    @include truncate-paragraph($paragraph-large-line-height, $paragraph-large-font-size);
  }

  .#{$grommet-namespace}paragraph--xlarge {
    @include truncate-paragraph($paragraph-xlarge-line-height, $paragraph-xlarge-font-size);
  }
}

.#{$grommet-namespace}card--direction-row {
  @include media-query(palm) {
    .#{$grommet-namespace}card__thumbnail {
      flex-basis: $size-small; // match basis for thumbnail in Card.js
    }
  }
}

.#{$grommet-namespace}card--selectable {
  &:hover > div {
    background-color: $card-hover-color;
    color: $hover-text-color;
    cursor: pointer;

    .#{$grommet-namespace}card__content {
      .#{$grommet-namespace}paragraph::after {
        background: linear-gradient(to right, transparent, $card-hover-color 50%);
      }
    }
  }
}
