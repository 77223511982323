.root {
  button {
    margin: 30px 0px;
    width: 100%;
    max-width: 100%;
  }
}

.table {
  margin-top: 50px;
}
